import { Button, Checkbox, Form, Input, Modal } from "antd";
import Modelimage1Img from "../../assets/images/model-img.png";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import useRequest from "../../hooks/useRequest";
import { useNavigate } from "react-router";
import useFirebase from "../../hooks/useFirebase";
import { ShowToast, Severty } from "../../helper/toast";
import encryptDecrypt from "../../helper/encryptDecrypt";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import LoginlogoImg from "../../assets/images/loginlogomain.png";
import React, { useRef } from "react";
import { InputOTP } from "antd-input-otp";
import GoogleLogin from "./GoogleSignUp";
import SignUp from "./SignUp";
import { showError } from "../../helper/functions";
import FaceBookLogin from "./FaceBookLogin";

const Login = ({ show, hide, url }) => {
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [passwordLoading, setPasswordLoading] = useState(false);
  const { request } = useRequest();
  const [resetModal, setResetModal] = useState(false);
  const [selected, setSelected] = useState();
  const [form] = Form.useForm();
  const [otpForm] = Form.useForm();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [signUp, setSignUp] = useState(false);
  const location = window.location.pathname;
  const navigate = useNavigate();
  const [latLong, setLatLong] = useState({
    lat: "",
    long: "",
  });

  const { fcmToken } = useFirebase();

  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMeChange = (checked) => {
    setRememberMe(checked);
  };

  const onFinish = (values) => {
    onSubmit(values);
  };

  const onSubmit = (values) => {
    const { email, password } = values;
    console.log(fcmToken, "fcmToken...............");
    if (!email)
      return ShowToast("Please enter email to sign in", Severty.ERROR);

    const payload = {
      password,
      device_token: fcmToken,
      device_type: "web",
      latitude: latLong.lat,
      longitude: latLong.long,
    };
    if (!email) return ShowToast("Please enter valid email ", Severty.ERROR);

    // return  console.log(payload, "fkjdhkd");

    setLoading(true);
    payload.email = email;
    payload.type = "Customer";
    request({
      url: apiPath.login,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          setIsLoggedIn(true);
          console.log("rememberMe", rememberMe);
          if (rememberMe?.target?.checked === true || rememberMe === true) {
            // Store the login state in local storage

            var emailEncrypt = encryptDecrypt.encryptEmail(values.email);
            var passwordEncrypt = encryptDecrypt.encryptPassword(
              values.password
            );

            localStorage.setItem("rememberMe", true);
            localStorage.setItem("ykmCe2AYEFTHobn", emailEncrypt);
            localStorage.setItem("ouUsippetc8S4Ry", passwordEncrypt);
          } else {
            localStorage.removeItem("rememberMe");
            localStorage.removeItem("ykmCe2AYEFTHobn");
            localStorage.removeItem("ouUsippetc8S4Ry");
          }
          localStorage.setItem("token", data.token);
          localStorage.setItem("userProfile", JSON.stringify(data.user));

          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.user);
          hide();
          if (
            location === "/" ||
            location === "/login" ||
            location === "/signup"
          )
            setTimeout(() => navigate("/home"), 200);
        } else {
          ShowToast(data.message, Severty.ERROR);

          setLoading(false);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        console.log(error?.response?.data, "error?.response?.data?.message");
        // const errorMessage = error?.response?.data?.message;
        // if (errorMessage?.includes("Your Account is not verified")) {
        //   verifyAccount(payload);
        //   handleSendOtp(payload)
        // }
        setLoading(false);
      },
    });
  };

  // const handleSendOtp = (values) => {
  //   // Send the OTP to the user's email

  //   let payload = {};
  //   payload.email =  values?.email ? values?.email : "" ;
  //   payload.use_for = "Login";

  //   request({
  //     url: apiPath.sendOtp,
  //     method: "POST",
  //     data: payload,
  //     onSuccess: (data) => {
  //       if (data.status) {
  //         ShowToast(data.message, Severty.SUCCESS)
  //       } else {
  //         ShowToast(data.message, Severty.ERROR);
  //       }
  //     },
  //     onError: (error) => {
  //       ShowToast(error?.response?.data?.message, Severty.ERROR);
  //     },
  //   });
  // };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleResetPassword = (email) => {
    // Send the OTP to the user's email
    setResetLoading(true);
    let payload = {};
    payload.email = email.email;
    payload.use_for = "Login";

    request({
      url: apiPath.sendOtp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          var emailResetEncrypt = encryptDecrypt.encryptEmail(email.email);
          localStorage.setItem("UGwdfdR2uHMM24N", emailResetEncrypt);
          setResetLoading(false);
          ShowToast(data.message, Severty.SUCCESS);
          setShowForgotModal(false);
          setShowOtpModal(true);
          setSelected({ email });
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setResetLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleVerifyOTP = (values) => {
    console.log(values, "valuess");
    let combinedOtp = values.otp;
    console.log(combinedOtp, "combinedOtp");

    if (combinedOtp.length !== 4) {
      ShowToast("Please enter the complete OTP", Severty.ERROR);
      return;
    }
    combinedOtp = values.otp ? values.otp.join("") : "";
    setVerifyLoading(true);
    // Verify the OTP entered by the user
    let payload = {};
    const savedEmail = localStorage.getItem("UGwdfdR2uHMM24N");
    var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
    payload.email = originalEmail;
    payload.otp = combinedOtp;
    payload.use_for = "Login";
    request({
      url: apiPath.verifyOtp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          setVerifyLoading(false);
          ShowToast(data.message, Severty.SUCCESS);
          setShowOtpModal(false);
          setSelected(null);
          setResetModal(true);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        setVerifyLoading(false);
        ShowToast(error?.response?.data?.message, Severty.ERROR);
      },
    });
  };

  const handleReset = (values) => {
    const { email, newPassword } = values;
    setPasswordLoading(true);
    // Reset the password with the new password entered by the user
    let payload = {};

    const savedEmail = localStorage.getItem("UGwdfdR2uHMM24N");
    var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
    payload.email = originalEmail;

    payload.password = newPassword;
    request({
      url: apiPath.resetPassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          setPasswordLoading(false);
          ShowToast(data.message, Severty.SUCCESS);
          setResetModal(false);
        } else {
          ShowToast(data.message, Severty.SUCCESS);
        }
      },
      onError: (error) => {
        setPasswordLoading(false);
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  const handleCancelReset = () => {
    setResetModal(false);
    // form.resetFields()
  };

  // ouUsippetc8S4Ry = Email,
  // ouUsippetc8S4Ry = password,
  // UGwdfdR2uHMM24N = resetEmail

  useEffect(() => {
    const savedEmail = localStorage.getItem("ykmCe2AYEFTHobn");
    const savedPassword = localStorage.getItem("ouUsippetc8S4Ry");

    if (savedEmail && savedPassword) {
      var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
      var originalPassword = encryptDecrypt.decryptEmail(savedPassword);

      form.setFieldsValue({
        email: originalEmail,
        password: originalPassword,
        remember: true,
      });
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  }, []);

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);
  };
  useEffect(() => {
    console.log(signUp, "sign-------------.");
  });

  useEffect(() => {
    let latitude, longitude;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;
        setLatLong({ lat: latitude, long: longitude });
      }, showError);
    } else {
      // ShowToast("Geolocation is not supported by this browser.", Severty.ERROR);
    }
  }, []);

  return (
    <>
      <Modal
        width={1249}
        title="Basic Modal"
        open={show}
        onCancel={hide}
        className="main-model_imgg1"
      >
        <div className="model-form-main-1">
          <div className="model-img">
            <img src={Modelimage1Img} alt="#" />
          </div>
          <div>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              className="row-col"
            >
              <div className="login-name">
                <img src={LoginlogoImg} /> <span>{lang("Login")}</span>
              </div>

              <Form.Item
                className="username"
                // label={lang("Email Address")}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: lang("Please enter a valid email address!"),
                  },
                  {
                    max: 255,
                    message: lang(
                      "Email address not more then 255 characters!"
                    ),
                  },
                  {
                    required: true,
                    message: lang("Please enter your email!"),
                  },
                ]}
              >
                <Input placeholder={lang("Enter Email")} />
              </Form.Item>
              <Form.Item
                className="password"
                // label={lang("Password")}
                name="password"
                rules={[
                  {
                    max: 255,
                    message: lang(
                      "Password should contain more then 255 characters!"
                    ),
                  },
                  {
                    required: true,
                    message: lang("Please enter your password!"),
                  },
                ]}
              >
                <Input.Password
                  // onCut={(e) => e.preventDefault()}
                  // onCopy={(e) => e.preventDefault()}
                  // onPaste={(e) => e.preventDefault()}
                  autoComplete="off"
                  placeholder={lang("Password")}
                />
              </Form.Item>
              <div className="forgot-pass">
                <Form.Item
                  name="remember"
                  className="aligin-center remamber-center"
                  valuePropName="checked"
                >
                  <Checkbox
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                  >
                    {lang("Remember me")}
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name="remember"
                  className="aligin-center"
                  valuePropName="checked"
                >
                  <a
                    onClick={() => {
                      setShowForgotModal(true);
                      hide();
                    }}
                  >
                    <a>{lang("Forgot Password?")}</a>
                  </a>
                </Form.Item>
              </div>
              <Form.Item>
                <Button
                  className="float-right"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {lang("Submit")}
                </Button>
              </Form.Item>
            </Form>
            <div className="social-signup-button">
              <GoogleLogin />
              <FaceBookLogin />
            </div>
          </div>
        </div>
      </Modal>

      {showForgotModal ? (
        <Modal
          width={1249}
          visible={showForgotModal}
          // title="Forgot Password"
          okText={lang("Send OTP")}
          onCancel={() => {
            setShowForgotModal(false);
            form.resetFields();
          }}
          footer={false}
          className="tab_modal second-modal second-modalmain000"
          okButtonProps={{
            form: "forget-pasword",
            htmlType: "submit",
            loading: resetLoading,
          }}
        >
          <div className="model-form-main-1">
            <div className="model-img">
              <img src={Modelimage1Img} alt="#" />
            </div>

            <Form
              id="forget-pasword"
              onFinish={handleResetPassword}
              layout="vertical"
            >
              <h4 className="modal_title_cls">
                {" "}
                <img src={LoginlogoImg} /> {lang("Forgot Password")}
              </h4>

              <Form.Item
                // label={lang("Email Address")}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: lang("Please enter a valid email address!"),
                  },
                  {
                    max: 255,
                    message: lang(
                      "Email address not more then 255 characters!"
                    ),
                  },
                  {
                    required: true,
                    message: lang("Please enter your email!"),
                  },
                ]}
              >
                <Input autoComplete="off" placeholder={lang("Enter Email")} />
              </Form.Item>
              <Form.Item>
                <Button
                  className="float-right"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {lang("Send Otp")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      ) : null}

      {showOtpModal ? (
        <Modal
          width={1249}
          visible={showOtpModal}
          okText={lang("Verify")}
          onCancel={() => {
            setShowOtpModal(false);
          }}
          footer={false}
          className="tab_modal main-otp-model"
          okButtonProps={{
            form: "verify-otp",
            htmlType: "submit",
            loading: verifyLoading,
          }}
        >
          <div className="model-form-main-1">
            <div className="model-img">
              <img src={Modelimage1Img} alt="#" />
            </div>
            <Form
              id="verify-otp"
              form={otpForm}
              onFinish={handleVerifyOTP}
              autoComplete="off"
              layout="vertical"
            >
              <h4 className="modal_title_cls">
                <img src={LoginlogoImg} /> {lang("Verify OTP")}
              </h4>

              <Form.Item
                name="otp"
                rules={[
                  { required: true, message: lang("Please enter the OTP") },
                  // { len: 4, message: lang("OTP must be 4 digits") },
                ]}
              >
                <InputOTP
                  className="otp-of-type"
                  autoSubmit={otpForm}
                  length={4}
                  inputType="numeric"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="float-right"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {lang("Submit")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      ) : null}

      {resetModal ? (
        <Modal
          width={1249}
          className="tab_modal second-modal main-three"
          visible={resetModal}
          // title="Reset Password"
          okText={lang("Update Password")}
          onCancel={handleCancelReset}
          okButtonProps={{
            form: "reset-password",
            htmlType: "submit",
            loading: passwordLoading,
          }}
          footer={false}
        >
          <div className="model-form-main-1">
            <div className="model-img">
              <img src={Modelimage1Img} alt="#" />
            </div>
            <Form
              id="reset-password"
              onFinish={(e) => handleReset(e)}
              layout="vertical"
              className="reset-pass-model"
            >
              <h4 className="modal_title_cls">
                <img src={LoginlogoImg} /> {lang("Update Password")}
              </h4>
              <Form.Item
                // label={lang("New Password")}
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: lang("Please enter your new password!"),
                  },
                  {
                    pattern: new RegExp(
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                    ),
                    message: lang(
                      "New password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character"
                    ),
                  },
                ]}
              >
                <Input.Password placeholder={lang("Enter New Password")} />
              </Form.Item>
              <Form.Item
                // label={lang("Confirm New Password")}
                name="confirm_new_password"
                dependencies={["newPassword"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: lang("Please enter the confirm password!"),
                  },
                  {
                    pattern: new RegExp(
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/
                    ),
                    message:
                      "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          lang("Password that you entered doesn't match!")
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={lang("Re-enter New Password")} />
              </Form.Item>
              <Form.Item>
                <Button
                  className="float-right"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {lang("Submit")}
                </Button>
              </Form.Item>
              <Form.Item></Form.Item>
            </Form>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default Login;
