import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Form,
  Modal,
  Input,
  Rate,
  Tabs,
  Collapse,
  Button,
  Image,
  Row,
  Col,
  Avatar,
  Carousel,
} from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion } from "react-bootstrap";
import { HeartFilled, HeartOutlined, PlusOutlined } from "@ant-design/icons";
import Service1Img from "../../assets/images/service-imgg.png";
import Service2Img from "../../assets/images/service-img-back.png";
import ArrowImg from "../../assets/images/ep_arrow-up.png";
import MassagelogoImg from "../../assets/images/massege.png";
import RightcheckImg from "../../assets/images/wirghtclick.png";
import Facebool2Img from "../../assets/images/facebook2.0.png";
import Instragram2Img from "../../assets/images/insta2.0.png";
import Youtube2Img from "../../assets/images/websiteimage.png";
import MaillogoImg from "../../assets/images/email.png";
import MainNewserviceImg from "../../assets/images/engement.png";
import NewMultiImg from "../../assets/images/Clip path group.jpg";
import PcallImg from "../../assets/images/callong.png";
import LocationImg from "../../assets/images/loation.png";
import Share2Img from "../../assets/images/service-share.png";
import notfound from "../../assets/images/not_found.png";
import NoService from "../../assets/images/service-main-not-att.png";
import NoPackage from "../../assets/images/NoPackage.png";
import NoService34 from "../../assets/images/gallery-not-attact.png";
import ScrollContainer from 'react-indiana-drag-scroll'

import { useLocation, useNavigate, useParams } from "react-router";
import InviteFriends from "../../components/InviteFriends";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { ShowToast, Severty } from "../../helper/toast";
import lang from "../../helper/langHelper";
import moment from "moment";
import AddBudget from "./AddBudget";
import Loader from "../../components/Loader";
import { RWebShare } from "react-web-share";
import ChatRepository from "../Message/ChatRepository";
import AddEvent from "../Event/AddEvent";
import ReadStory from "../../modals/ReadStory";
import PackageAddBudget from "./PackageAddBudget";
import { useAppContext } from "../../context/AppContext";
import ImageViewer from "react-simple-image-viewer";
import NoData from "../../assets/images/no-data.png";
import Login from "../Auth/Login";
import { formatStringDate } from "../../helper/functions";

const weekDays = [
  { name: lang("Sunday"), shortName: "sunday" },
  { name: lang("Monday"), shortName: "monday" },
  { name: lang("Tuesday"), shortName: "tuesday" },
  { name: lang("Wednesday"), shortName: "wednesday" },
  { name: lang("Thursday"), shortName: "thursday" },
  { name: lang("Friday"), shortName: "friday" },
  { name: lang("Saturday"), shortName: "saturday" },
];

function ServicedetailsIndex() {
  const { TabPane } = Tabs;
  const { Panel } = Collapse;
  const navigate = useNavigate();
  const { language } = useAppContext();
  const { isLoggedIn } = useAuthContext();
  const params = useParams();
  const { state } = useLocation();
  const { request } = useRequest();
  const [provider, setProvider] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showBudget, setShowBudget] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showPkgBudget, setShowPkgBudget] = useState(false);
  const [showNewEvent, setShowNewEvent] = useState(false);
  const [showSrcImages, setShowSrcImages] = useState(false);
  const [srcImages, setSrcImages] = useState([]);
  const [selected, setSelected] = useState({
    attributes: undefined,
    service_id: undefined,
    packages_id: undefined,
    vendor_id: undefined,
    price: undefined,
    package: undefined,
    addBudgetType: "serviceBudget",
  });
  const chatRepo = ChatRepository();
  const serviceRefs = useRef({});
  const [isRefsPopulated, setIsRefsPopulated] = useState(false);

  const getProvider = (id) => {
    // setLoading(true);
    request({
      url: `${apiPath.getProvider}/${id}`,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) return;
        setLoading(false);
        setProvider(data?.data);
        console.log(data, "getProvider");
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    getProvider(params.id);
  }, [refresh, params.id]);

  const requestNavigate = (useFor) => {
    if (!isLoggedIn) return setShowLogin(true);
    navigate(`/request-quote/${params.id}?useFor=${useFor}`);
  };

  const addToFavorite = (id, type) => {
    if (!isLoggedIn) return setShowLogin(true);
    const payload = { type: type };
    if (type === "Provider") payload.vendor_id = id;
    if (type === "Service")
      payload.service = { service_id: id, provider_id: params?.id };
    if (type === "Packages")
      payload.packages = {
        packages_id: id?._id,
        provider_id: params?.id,
      };
    request({
      url: apiPath.baseURL + "/app/wishlist",
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          getProvider(params.id);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        console.log(err);
        ShowToast(err, Severty.ERROR);
      },
    });
  };

  const handleFavoriteClick = () => {
    addToFavorite(provider?._id, "Provider");
  };

  const handleShareNavigate = () => {
    if (!isLoggedIn) return setShowLogin(true);
    navigate("/message");
  };

  useEffect(() => {
    if (provider?.services?.length > 0) {
      setIsRefsPopulated(true);
    }
  }, [provider?.services]);

  useEffect(() => {
    if (
      isRefsPopulated &&
      state?.serviceId &&
      serviceRefs.current[state?.serviceId]
    ) {
      console.log("Scroll started......");
      setTimeout(() => {
        serviceRefs.current[state.serviceId]?.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    }

    // console.log(state?.serviceId, "state?.serviceId", serviceRefs.current[state?.serviceId], "serviceRefs.current[state.serviceId]");
  }, [state?.serviceId, isRefsPopulated]);


  const showName = (value) => {
    return language !== "en" && language !== null
      ? value?.[`${language}_name`] ?? value?.name
      : value?.name;
  };

  const showAttrValue = (item) => {
    return language !== "en" && language !== null
      ? item?.[`${language}_value`] ?? item?.value
      : item?.value;
  };

  return (
    <Main>
      <>
        <section>
          <div className="service-backg">
            {provider?.cover_photo ? (
              <div className="main-divv-bggg">
                <img src={provider?.cover_photo} alt="" />
              </div>
            ) : loading ? (
              <Loader />
            ) : (
              <>
                <div className="main-divv-bggg">
                  <img src={Service1Img} alt="" />
                </div>
                <div className="ser-img-back">
                  <img src={Service2Img} alt="" />
                </div>
              </>
            )}

            <div className="for-like-share">
              <div onClick={handleFavoriteClick} className="heart-image">
                {provider?.is_fav ? (
                  <HeartFilled
                    style={{
                      color: "red",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <HeartOutlined
                    style={{
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
              <RWebShare
                data={{
                  title: lang("Check out this page!"),
                  text: lang("Here is some interesting content to share."),
                  url: window.location.href,
                }}
                disableNative={true}
                sites={[
                  "linkedin",
                  "facebook",
                  "whatsapp",
                  "twitter",
                  "reddit",
                ]}
                onClick={() => {
                  if (!isLoggedIn) {
                    return setShowLogin(true);
                  } else {
                    console.log("Shared successfully!");
                  }
                }}
              >
                <Button
                  onClick={() => {
                    if (!isLoggedIn) {
                      return setShowLogin(true);
                    } else {
                      handleShareNavigate();
                    }
                  }}
                >
                  <img src={Share2Img} alt="Share Icon" />
                </Button>
              </RWebShare>
            </div>
          </div>
        </section>
        <section className="request-bag">
          <div className="container for-all-short-w">
            <div className="jellow-maan-wedding">
              <div className="jweelers-cart-main">
                <div className="jwellers--txt-mmain">
                  <div className="jwellers--txt-mmain-img">
                    <Image
                      preview={true}
                      src={
                        provider?.logo
                          ? provider.logo
                          : provider?.selected_category_id?.image
                          ? provider.selected_category_id.image
                          : notfound
                      }
                      alt=""
                    />
                  </div>
                  <div className="jwellers--txt-mmain-img-txt">
                    <h1>
                      {" "}
                      {language !== "en" && language !== null
                        ? provider?.[`${language}_name`] ?? provider?.name
                        : provider?.name}
                    </h1>

                    <p>
                      {provider?.category_id &&
                        provider?.category_id?.length > 0 &&
                        provider?.category_id?.map((item, index) => (
                          <span key={item._id}>
                            {language !== "en" && language !== null
                              ? item?.[`${language}_name`] ?? item?.name
                              : item?.name}
                            {index < provider.category_id.length - 1 && " |"}
                          </span>
                        ))}
                    </p>

                    <div
                      className="star-give"
                      onClick={() => {
                        if (!isLoggedIn) {
                          return setShowLogin(true);
                        } else {
                          navigate(`/provider-rating/${provider?._id}`);
                        }
                      }}
                    >
                      <div className="star-imgg">
                        <h2>{provider?.avg_rating?.toFixed(1) ?? 0}</h2>
                        <Rate
                          disabled={true}
                          allowHalf
                          value={
                            provider?.avg_rating
                              ? Number(provider?.avg_rating)
                              : 0
                          }
                        />
                      </div>
                      <div className="reveives-sec">
                        <img
                          src={ArrowImg}
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                        <a>
                          {provider?.review_count ?? 0} {lang("reviews")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {provider?.is_featured ? (
                  <div className="fiturs-btn">
                    <h2>{lang("Featured")}</h2>
                  </div>
                ) : (
                  ""
                )}
                <div
                  className="massage-text"
                  onClick={() => {
                    const groupdId =
                      JSON.parse(localStorage.getItem("userProfile"))?._id +
                      "_" +
                      provider?.user_id;

                    let oppositeUserData = {
                      image: provider?.logo,
                      mobileNumber: provider?.mobile_number,
                      name: provider?.name,
                      unseenCount: 0,
                    };
                    let quotationData = {
                      title: "",
                      image: "",
                      budget: "",
                      description: "",
                      isQuotation: false,
                    };

                    chatRepo.createGroup(
                      groupdId,
                      JSON.parse(localStorage.getItem("userProfile"))?._id,
                      provider?.user_id,
                      oppositeUserData,
                      quotationData
                    );
                    if (!isLoggedIn) {
                      return setShowLogin(true);
                    } else {
                      navigate("/chat");
                    }
                  }}
                >
                  <a>
                    <img src={MassagelogoImg} alt="" />
                    {lang("Message")}
                  </a>
                </div>
              </div>

              <Tabs
                defaultActiveKey={state?.tab || "1"}
                className="offering-tabbs"
                tabBarGutter={8} // Adjust the gutter between tabs
                type="line" // Ensures the tabs are displayed as a line
                tabPosition="top" // Keeps the tabs at the top
                moreIcon={<span>{lang("More")}</span>}
              >
                {/* Offrings Services--------> */}
                <TabPane
                  tab={lang("Service offerings")}
                  key="1"
                  className="Offerings-img"
                >
                  {loading ? (
                    <Loader />
                  ) : provider?.services && provider?.services?.length ? (
                    <>
                      <div className="offerring-section">
                        <Row gutter={[24, 24]}>
                          {provider?.services?.map((item) => {
                            return (
                              <Col span={24} key={item._id}>
                                <div
                                  ref={(el) => {
                                    if (el) {
                                      serviceRefs.current[item._id] = el;
                                    }
                                  }}
                                  id={item?._id}
                                  className="offers-container"
                                >
                                  <div className="offers-main offers-main-main-876">
                                    <div className="provider-offer-img">
                                      <Carousel
                                        arrows
                                        dots={false}
                                        infinite={false}
                                      >
                                        <Image
                                          className="provider-carousel"
                                          preview={true}
                                          src={
                                            item?.cover_image
                                              ? item?.cover_image
                                              : item?.service_id?.image
                                              ? item?.service_id?.image
                                              : provider?.logo
                                              ? provider?.logo
                                              : provider?.selected_category_id
                                                  ?.image || notfound
                                          }
                                        />

                                        {item?.images?.length
                                          ? item?.images?.map((item) => (
                                              <Image
                                                className="provider-carousel"
                                                preview={true}
                                                src={item}
                                              />
                                            ))
                                          : ""}
                                      </Carousel>
                                    </div>
                                    <div className="offers-details">
                                      <div className="offer-txt provider-price">
                                        <h4>
                                          {item?.name
                                            ? item?.name
                                            : language !== "en" &&
                                              language !== null
                                            ? item?.service_id?.[
                                                `${language}_name`
                                              ] ?? item?.service_id?.name
                                            : item?.service_id?.name}
                                        </h4>

                                        <h5>
                                          JOD{" "}
                                          {item?.discount_price ??
                                            item?.discount_price}{" "}
                                          {item?.original_price ? (
                                            item?.discount_price ? (
                                              <span>
                                                {" "}
                                                <del>
                                                  {item?.original_price}
                                                </del>{" "}
                                              </span>
                                            ) : (
                                              item?.original_price
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </h5>
                                      </div>

                                      <div className="offer-listedbar">
                                        <ul>
                                          <Row gutter={[12, 12]}>
                                            {item?.attribute_id
                                              ?.filter(
                                                (attribute, index, self) =>
                                                  attribute?._id !== null &&
                                                  attribute?.type !== null &&
                                                  attribute?.value !== null &&
                                                  attribute?.value !== false &&
                                                  attribute?.value !==
                                                    undefined &&
                                                  attribute?.value !== "" &&
                                                  !(
                                                    Array.isArray(
                                                      attribute?.value
                                                    ) &&
                                                    attribute?.value.length &&
                                                    attribute?.value[0] ===
                                                      false
                                                  ) &&
                                                  index ===
                                                    self.findIndex(
                                                      (a) =>
                                                        a._id === attribute?._id
                                                    )
                                              )
                                              ?.map((attribute) => {
                                                let shouldRender = false;
                                                let content = null;
                                                if (
                                                  attribute?.type ===
                                                    "button" &&
                                                  Array.isArray(
                                                    attribute?.value
                                                  ) &&
                                                  attribute?.value[0] === true
                                                ) {
                                                  shouldRender = true;
                                                  content = (
                                                    <>
                                                      <img
                                                        src={RightcheckImg}
                                                        alt=""
                                                      />
                                                      <span>
                                                        {showName(attribute)}{" "}
                                                        {attribute?.info
                                                          ? `(${attribute?.info})`
                                                          : ""}
                                                      </span>
                                                    </>
                                                  );
                                                }

                                                // Handle "textbox" type
                                                else if (
                                                  attribute?.type ===
                                                    "textbox" &&
                                                  Array.isArray(
                                                    attribute?.value
                                                  ) &&
                                                  attribute?.value.length
                                                ) {
                                                  shouldRender = true;
                                                  content = (
                                                    <>
                                                      <img
                                                        src={RightcheckImg}
                                                        alt=""
                                                      />
                                                      <span>
                                                        {showName(attribute)}{" "}
                                                        {attribute?.value
                                                          ?.length
                                                          ? `(${attribute?.value[0]})`
                                                          : ""}
                                                      </span>
                                                    </>
                                                  );
                                                }

                                                // Handle "boolean" type
                                                else if (
                                                  attribute?.type ===
                                                    "boolean" &&
                                                  Array.isArray(
                                                    attribute?.value
                                                  ) &&
                                                  attribute?.value.length &&
                                                  attribute?.value[0] === true
                                                ) {
                                                  shouldRender = true;
                                                  content = (
                                                    <>
                                                      <img
                                                        src={RightcheckImg}
                                                        alt=""
                                                      />
                                                      <span>
                                                        {showName(attribute)}
                                                      </span>
                                                    </>
                                                  );
                                                }

                                                // Handle "file" or "package" types
                                                else if (
                                                  attribute?.type === "file" ||
                                                  attribute?.type === "package"
                                                ) {
                                                  shouldRender = true;
                                                  content = (
                                                    <>
                                                      <img
                                                        src={RightcheckImg}
                                                        alt=""
                                                      />
                                                      {showName(attribute)}
                                                    </>
                                                  );
                                                }

                                                // Handle other types with values
                                                else if (
                                                  attribute?.value?.length
                                                ) {
                                                  shouldRender = true;
                                                  content = (
                                                    <>
                                                      <img
                                                        src={RightcheckImg}
                                                        alt=""
                                                      />
                                                      {showName(attribute)}{" "}
                                                      {Array.isArray(
                                                        attribute?.value
                                                      ) &&
                                                        `(${showAttrValue(
                                                          attribute
                                                        ).join(", ")})`}
                                                    </>
                                                  );
                                                }

                                                // Only render the <li> if there's valid content
                                                return shouldRender ? (
                                                  <Col
                                                    span={24}
                                                    md={12}
                                                    xxl={8}
                                                    key={attribute?._id?._id}
                                                  >
                                                    <li>
                                                      <div className="provider-list-name">
                                                        {content}
                                                      </div>
                                                    </li>
                                                  </Col>
                                                ) : null;
                                              })}
                                          </Row>
                                        </ul>
                                      </div>

                                      <div className="main-new-button-all-div">
                                        <div className="offer-button mt-0 mb-0">
                                          <button
                                            className="offer-button1"
                                            onClick={() =>
                                              addToFavorite(
                                                item?._id,
                                                "Service"
                                              )
                                            }
                                          >
                                            {item?.is_fav
                                              ? lang("Remove to Wishlist")
                                              : lang("Add to Wishlist")}{" "}
                                          </button>
                                          <button
                                            className="offer-button2"
                                            onClick={() => {
                                              if (!isLoggedIn) {
                                                return setShowLogin(true);
                                              } else {
                                                setShowBudget(true);
                                                setSelected({
                                                  attributes:
                                                    item?.attribute_id,
                                                  service_id: item?._id,
                                                  category_id:
                                                    item?.selected_category_id,
                                                  vendor_id: provider?._id,
                                                  price: item?.discount_price
                                                    ? item?.discount_price
                                                    : item?.original_price,
                                                  showEvent: true,
                                                  addBudgetType:
                                                    "serviceBudget",
                                                });
                                              }
                                            }}
                                          >
                                            {lang("Add to Budget")}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                        </Row>
                      </div>
                      <div
                        className="requesta-quote"
                        onClick={() => requestNavigate("service")}
                      >
                        <a>{lang("Request a Quote")}</a>
                      </div>
                    </>
                  ) : (
                    <div className="wedding-main">
                      <div className="nosub-ccc">
                        <img src={NoData} alt="" />
                      </div>
                    </div>
                  )}
                </TabPane>

                {/* Packages Offrings------------> */}
                <TabPane
                  tab={lang("Package offerings")}
                  key="2"
                  className="Offerings-img"
                >
                  {loading ? (
                    <Loader />
                  ) : provider?.packages && provider?.packages?.length ? (
                    <>
                      <div className="offerring-section">
                        <Row gutter={[24, 24]}>
                          {provider?.packages?.map((item) => (
                            <Col span={24} key={item._id}>
                              <div className="offers-container">
                                <div className="offers-main offers-main-main-876">
                                  <div className="offer-img offer-img-offer-img-new-man3">
                                    <Image
                                      src={
                                        item?.cover_image
                                          ? item?.cover_image
                                          : provider?.logo
                                          ? provider?.logo
                                          : provider?.selected_category_id
                                              ?.image || notfound
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="offer-txt">
                                    <h4>
                                      {language !== "en" && language !== null
                                        ? item?.[`${language}_name`] ??
                                          item?.name
                                        : item?.name}
                                    </h4>
                                    <h3>
                                      {language !== "en" && language !== null
                                        ? item?.[`${language}_description`] ??
                                          item?.description
                                        : item?.description}{" "}
                                    </h3>

                                    <h5>
                                      JOD{" "}
                                      {item?.discount_price ??
                                        item?.discount_price}{" "}
                                      {item?.original_price ? (
                                        item?.discount_price ? (
                                          <span>
                                            {" "}
                                            <del>
                                              {item?.original_price}
                                            </del>{" "}
                                          </span>
                                        ) : (
                                          item?.original_price
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </h5>
                                  </div>
                                </div>
                                <Row gutter={[24, 24]}>
                                  {item?.package_service_id?.map((service) => (
                                    <Col span={24} md={12}>
                                      <Accordion>
                                        <Accordion.Item
                                          eventKey="0"
                                          className=" offers-mainnew-mainhhhh234"
                                          key={service?.service_id?._id}
                                        >
                                          <Accordion.Header className="">
                                            <div className="offers-main">
                                              <div className="offer-img">
                                                <Image
                                                  src={
                                                    service?.cover_image
                                                      ? service?.cover_image
                                                      : service?.service_id
                                                          ?.image
                                                      ? service?.service_id
                                                          ?.image
                                                      : notfound
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                              <div className="offer-txt">
                                                <h4>
                                                  {service?.name
                                                    ? service?.name
                                                    : language !== "en" &&
                                                      language !== null
                                                    ? service?.service_id?.[
                                                        `${language}_name`
                                                      ]
                                                    : service?.service_id?.name}
                                                </h4>

                                                <h5>
                                                  JOD{" "}
                                                  {service?.discount_price ??
                                                    service?.discount_price}{" "}
                                                  {service?.original_price ? (
                                                    service?.discount_price ? (
                                                      <span>
                                                        {" "}
                                                        <del>
                                                          {
                                                            service?.original_price
                                                          }
                                                        </del>{" "}
                                                      </span>
                                                    ) : (
                                                      service?.original_price
                                                    )
                                                  ) : (
                                                    ""
                                                  )}
                                                </h5>
                                              </div>
                                            </div>
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div>
                                              <ul>
                                                {service?.attribute_id
                                                  ?.filter(
                                                    (attribute) =>
                                                      attribute?._id !== null &&
                                                      attribute?.type !==
                                                        null &&
                                                      attribute?.value !==
                                                        null &&
                                                      attribute?.value !==
                                                        false &&
                                                      attribute?.value !==
                                                        undefined &&
                                                      !(
                                                        Array.isArray(
                                                          attribute?.value
                                                        ) &&
                                                        attribute.value
                                                          .length &&
                                                        attribute.value[0] ===
                                                          false
                                                      )
                                                  )
                                                  ?.map((attribute) => (
                                                    <li key={attribute?._id}>
                                                      <div className="provider-list-name">
                                                        {attribute?.type ===
                                                        "button" ? (
                                                          Array.isArray(
                                                            attribute?.value
                                                          ) &&
                                                          attribute
                                                            ?.value[0] ===
                                                            true && (
                                                            <>
                                                              <img
                                                                src={
                                                                  RightcheckImg
                                                                }
                                                                alt=""
                                                              />
                                                              <span>
                                                                {
                                                                  attribute?.name
                                                                }{" "}
                                                                {attribute?.info
                                                                  ? `(${attribute?.info})`
                                                                  : ""}
                                                              </span>
                                                            </>
                                                          )
                                                        ) : attribute?.type ===
                                                          "boolean" ? (
                                                          Array.isArray(
                                                            attribute?.value
                                                          ) &&
                                                          attribute?.value
                                                            ?.length &&
                                                          attribute
                                                            ?.value?.[0] ===
                                                            true ? (
                                                            <>
                                                              <img
                                                                src={
                                                                  RightcheckImg
                                                                }
                                                                alt=""
                                                              />
                                                              <span>
                                                                {" "}
                                                                {showName(
                                                                  attribute
                                                                )}{" "}
                                                              </span>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )
                                                        ) : attribute?.type ===
                                                            "file" ||
                                                          attribute?.type ===
                                                            "package" ? (
                                                          <>
                                                            <img
                                                              src={
                                                                RightcheckImg
                                                              }
                                                              alt=""
                                                            />
                                                            {showName(
                                                              attribute
                                                            )}{" "}
                                                          </>
                                                        ) : attribute?.value
                                                            ?.length ? (
                                                          <>
                                                            <img
                                                              src={
                                                                RightcheckImg
                                                              }
                                                              alt=""
                                                            />
                                                            {showName(
                                                              attribute
                                                            )}{" "}
                                                            {Array.isArray(
                                                              attribute?.value
                                                            ) &&
                                                              `(${showAttrValue(
                                                                attribute
                                                              )?.join(", ")})`}
                                                          </>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    </li>
                                                  ))}
                                              </ul>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                    </Col>
                                  ))}
                                </Row>

                                <div className="offer-button">
                                  <button
                                    className="offer-button1"
                                    onClick={() =>
                                      addToFavorite(item, "Packages")
                                    }
                                  >
                                    {item?.is_fav
                                      ? lang("Remove to Wishlist")
                                      : lang("Add to Wishlist")}{" "}
                                  </button>
                                  <button
                                    className="offer-button2"
                                    onClick={() => {
                                      if (!isLoggedIn) {
                                        return setShowLogin(true);
                                      } else {
                                        setShowPkgBudget(true);
                                        setSelected({
                                          package_id: item?._id,
                                          category_id:
                                            item?.selected_category_id,
                                          showEvent: true,
                                          addBudgetType: "pkgBudget",
                                          price: item?.discount_price
                                            ? item?.discount_price
                                            : item?.original_price,
                                        });
                                      }
                                    }}
                                  >
                                    {lang("Add to Budget")}
                                  </button>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                      <div
                        className="requesta-quote"
                        onClick={() => requestNavigate("package")}
                      >
                        <a>{lang("Request a Quote")}</a>
                      </div>
                    </>
                  ) : (
                    <div className="wedding-main">
                      <div className="nosub-ccc">
                        <img src={NoData} alt="" />
                      </div>
                    </div>
                  )}
                </TabPane>

                {/* Gallary Section---> */}
                <TabPane tab={lang("Gallery")} key="3">     
                  <GalleryDetails provider={provider} loading={loading} refresh={() => getProvider(params.id) } />
                </TabPane>

                {/* Details Section---> */}
                <TabPane tab={lang("Details")} key="4">
                  <ProviderDetails
                    provider={provider}
                    loading={loading}
                    refresh={() => setRefresh((prev) => !prev)}
                    setShowLogin={setShowLogin}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </section>

        {/* <InviteFriends /> */}

        {showLogin && (
          <Login
            show={showLogin}
            hide={() => {
              setShowLogin(false);
            }}
            url={true}
          />
        )}

        {showBudget && (
          <AddBudget
            show={showBudget}
            hide={() => {
              setShowBudget((prev) => !prev);
              setSelected("");
            }}
            showNewEvent={() => {
              setShowNewEvent(true);
              setShowBudget(false);
            }}
            selected={selected}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}

        {showPkgBudget && (
          <PackageAddBudget
            show={showPkgBudget}
            hide={() => {
              setShowPkgBudget((prev) => !prev);
              setSelected("");
            }}
            showNewEvent={() => {
              setShowNewEvent(true);
              setShowPkgBudget(false);
            }}
            selected={selected}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}

        {showNewEvent && (
          <AddEvent
            show={showNewEvent}
            hide={() => {
              setShowNewEvent((prev) => !prev);
              setSelected("");
            }}
            budgetData={selected}
            refresh={() => setRefresh((prev) => !prev)}
          />
        )}
        {showSrcImages && (
          <ImageViewer
            className="priview-modal"
            src={srcImages}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={() => setShowSrcImages(false)}
          />
        )}
      </>
    </Main>
  );
}

const GalleryDetails = ({ provider, loading, refresh }) => {
  const supportedImageExtensions = [
    ".png",
    ".jpg",
    ".jpeg",
    ".avif",
    ".webp",
    ".gif",
  ];
  const supportedVideoExtensions = [".mp4", ".ogg", ".webm", ".mpeg"];

  const [showStory, setShowStory] = useState(false);
  const [showGallary, setShowGallery] = useState(false);
  const [storyData, setStoryData] = useState([]);
  const [providerData, setProviderData] = useState(provider);
  const [gallaryImages, setGallaryImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = provider?.image?.filter((item) =>
    supportedImageExtensions.some((ext) => item.toLowerCase().endsWith(ext))
  );

  const videos = provider?.image?.filter((item) =>
    supportedVideoExtensions.some((ext) => item.toLowerCase().endsWith(ext))
  );


  const isVideo = (url)=> {
    return supportedVideoExtensions.some((ext) =>url?.toLowerCase().endsWith(ext));
   } 
 
  return (
    <>
      <div className="scroll-bar">
        {provider?.story?.length > 0 ? (
          <div className="story-container">
            <ScrollContainer className="scroll-container">
              <div className="story-container">
                {provider?.story?.map((item) => (
                  <div className="stories-grid">
                    {
                      <div
                      style={{cursor : "pointer"}}
                        key={item?._id}
                        className="story-item"
                        onClick={() => {
                          setShowStory(true);
                          setStoryData(item);
                        }}
                      >
                        <div className="avatar-container">
                          {
                            Array.isArray(item?.image)?isVideo(item?.image[0]) ?
                            //  <video autoPlay={false} muted style={{ display: "block" }} controls src={item?.image} onClick={(e) => e.stopPropagation()} /> 
                            <Avatar preview={false} src={provider?.logo ? provider?.logo : provider?.selected_category_id?.image ? provider?.selected_category_id?.image : item?.image } />
    
                             :  <Avatar src={item?.image} />:isVideo(item?.image[0]) ?
                             //  <video autoPlay={false} muted style={{ display: "block" }} controls src={item?.image} onClick={(e) => e.stopPropagation()} /> 
                             <Avatar preview={false} src={provider?.logo ? provider?.logo : provider?.selected_category_id?.image ? provider?.selected_category_id?.image : item?.image } />
     
                              :  <Avatar src={item?.image} />
                          }
                        

                        </div>
                        <span className="man-34span">
                          {formatStringDate(item?.created_at)}
                        </span>
                      </div>
                    }
                  </div>
                ))}
              </div>
            </ScrollContainer>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="gallery-main-img_2">
        {loading ? (
          <Loader />
        ) : (
          <>
            {images.length > 0
              ? images.map((item, index) => (
                  <div
                    className="gallery-items02"
                    key={index}
                    onClick={() => {
                      setShowGallery(true);
                      setGallaryImages(images);
                      setCurrentImageIndex(index);
                    }}
                  >
                    <Image preview={false} src={item} />
                  </div>
                ))
              : ""}
            {videos.length > 0
              ? videos.map((item, index) => (
                  <div
                    className="gallery-items02"
                    key={index}
                    // onClick={() => {
                    //   setShowGallery(true);
                    //   setGallaryImages(videos);
                    //   setCurrentImageIndex(index);
                    // }}
                  >
                    <video controls src={item} />
                  </div>
                ))
              : ""}
            {!images.length && !videos?.length && (
              <div className="wedding-main">
                <div className="nosub-ccc">
                  <img src={NoData} alt="" />
                </div>
              </div>
            )}
           
          </>
        )}
      </div>
      {showStory && (
        <ReadStory
          show={showStory}
          hide={() => {
            setShowStory(false);
            setStoryData("");
          }}
          data={storyData}
          providerData={providerData}
          refresh={()=> refresh()}
        />
      )}
      {showGallary && (
        <ImageViewer
          className="priview-modal"
          src={gallaryImages}
          currentIndex={currentImageIndex}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setShowGallery(false)}
        />
      )}
    </>
  );
};

const ProviderDetails = ({ provider, loading, refresh, setShowLogin }) => {
  const [show, setShow] = useState(false);
  const [form] = Form.useForm();
  const [showFullNumber, setShowFullNumber] = useState(false);
  const { request } = useRequest();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuthContext();
  const { language } = useAppContext();

  const sendReport = (values) => {
    const payload = { ...values };
    payload.vendor_id = provider?._id ? provider?._id : "";
    request({
      url: `${apiPath.reportProvider}`,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setShow(false);
          form.resetFields();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  const callCount = () => {
    let vendor_id = provider?._id ? provider._id : "";
    request({
      url: `${apiPath.getCount}/${vendor_id}`, // Pass vendor_id in the URL
      method: "GET",
      onSuccess: (data) => {
        if (data.status) {
          // ShowToast(data.message, Severty.SUCCESS);
          setShowFullNumber(true);
          // refresh();
        } else {
          // ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        // ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };
  console.log(showFullNumber, "show full number-----------");
  const twitterNavigate = (link) => {
    window.open(link || "https://planit-co.com", "_blank");
  };

  const instaNavigate = (link) => {
    window.open(link || "https://www.instagram.com", "_blank");
  };

  const fbNavigate = (link) => {
    window.open(link || "https://www.facebook.com", "_blank");
  };

  const blurMobileNumber = (number) => {
    const visibleDigits = 5; // Number of digits to keep visible
    const visiblePart = number.slice(0, -visibleDigits);
    const blurredPart = "*".repeat(visibleDigits);
    return `${visiblePart}${blurredPart}`;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="details-maain-text">
            {provider?.email && (
              <div className="email-img">
                <img src={MaillogoImg} alt="" />
                <h3>{provider?.email}</h3>
              </div>
            )}
            {/* {provider?.country_code && provider?.mobile_number && (
              <div className="email-img">
                <img src={PcallImg} alt="" />
                <h3>{`+${provider.country_code}-${blurMobileNumber(provider.mobile_number)}`}</h3>
              </div>
            )} */}

            {provider?.country_code && provider?.mobile_number && (
              <div className="email-img">
                <img src={PcallImg} alt="Phone Call Icon" />
                <h3
                  onClick={() => {
                    if (!isLoggedIn) {
                      return setShowLogin(true);
                    }
                    if (!showFullNumber) {
                      callCount();
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {`+${provider.country_code}-`}
                  {showFullNumber
                    ? provider.mobile_number
                    : blurMobileNumber(provider.mobile_number)}
                </h3>
              </div>
            )}

            {provider?.map_address && (
              <div className="location-main">
                <div className="location-img">
                  <img src={LocationImg} alt="" />
                </div>
                <div className="location-txt">
                  <h2>
                    <a
                      href={`https://www.google.com/maps?q=${provider?.latitude},${provider?.longitude}`}
                      target="_blank"
                    >
                      {provider?.map_address}
                    </a>
                  </h2>
                </div>
              </div>
            )}

            {/* {provider?.sub_category_id?.length && (
              <div className="email-img">
                <h3>
                  {lang("Sub Category")} :{" "}
       
                     {provider?.sub_category_id &&
                        provider?.sub_category_id?.length > 0 &&
                        provider?.sub_category_id?.map((item, index) => (
                          <span key={item._id}>
                            {language !== "en" && language !== null
                              ? item?.[`${language}_name`] ?? item?.name
                              : item?.name}
                            {index < provider.sub_category_id.length - 1 &&
                              " |"}
                          </span>
                        ))}
                </h3>        
              </div>
            )} */}
          </div>
          {provider?.eventtype_id && (
            <>
              {provider?.eventtype_id?.length ? <h4>{lang("Events")}:</h4> : ""}
              <div className="events-name-tt" style={{ marginBottom: "10px" }}>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  {provider.eventtype_id.map((eventType, index) => (
                    <li key={index} style={{ marginBottom: "5px" }}>
                      {language !== "en" && language !== null
                        ? eventType?.[`${language}_name`] ?? eventType?.name
                        : eventType?.name}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
          {provider?.description ? (
            <div className="about-page-main">
              <h2>{lang("About")}</h2>
              <p className="for-m">
                {provider?.description
                  ? language !== "en" && language !== null
                    ? provider?.[`${language}_description`] ??
                      provider?.description
                    : provider?.description
                  : ""}
              </p>
            </div>
          ) : (
            ""
          )}
          <div className="business-hours">
            <h2>{lang("Business Hours")}</h2>
            <WorkingDaysDisplay provider={provider} />
          </div>
          {provider?.twitter_link ||
          provider?.facebook_link ||
          provider?.instagram_link ? (
            <div className="Social-links">
              <h2>{lang("Social Links")}</h2>
              <div className="Social-Links-logo">
                {provider?.twitter_link ? (
                  <img
                    src={Youtube2Img}
                    alt="#"
                    onClick={() => twitterNavigate(provider?.twitter_link)}
                  />
                ) : (
                  ""
                )}
                {provider?.facebook_link ? (
                  <img
                    src={Facebool2Img}
                    onClick={() => fbNavigate(provider?.facebook_link)}
                  />
                ) : (
                  ""
                )}
                {provider?.instagram_link ? (
                  <img
                    src={Instragram2Img}
                    onClick={() => instaNavigate(provider?.instagram_link)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="report-button">
            <Button>
              <a
                onClick={() => {
                  if (!isLoggedIn) {
                    return setShowLogin(true);
                  } else {
                    setShow(true);
                  }
                }}
              >
                {" "}
                {lang("Report")}
              </a>
            </Button>
            <Button>
              <a
                onClick={() => {
                  if (!isLoggedIn) {
                    return setShowLogin(true);
                  } else {
                    navigate(`/provider-rating/${provider?._id}`);
                  }
                }}
              >
                {" "}
                {lang("Rate")} &amp; {lang("Reviews")}
              </a>
            </Button>
          </div>{" "}
        </>
      )}
      {show && (
        <Modal
          open={show}
          width={550}
          okText={lang("Submit")}
          cancelText={lang("Cancel")}
          onCancel={() => setShow(false)}
          okButtonProps={{
            form: "create",
            htmlType: "submit",
            loading: loading,
            className: "btn-primary-ft",
          }}
          className="tab_modal deleteWarningModal modal01"
          cancelButtonProps={{
            className: "btn-primary-cancel",
            htmlType: "Back",
          }}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form
            id="create"
            form={form}
            onFinish={sendReport}
            layout="vertical"
            className="row-col"
          >
            <div className="add_user_title">
              <h4 className="modal_title_cls">{lang("Send Report")}</h4>
            </div>
            <Row gutter={[16, 0]}>
              <Col xl={24} md={24} span={24}>
                <Form.Item
                  className="username"
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter a Comment...!"),
                    },
                  ]}
                >
                  <Input.TextArea pl aceholder={lang("Enter Comment...")} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
};

const WorkingDaysDisplay = ({ provider }) => {
  const workingDaysMap = provider?.working_days?.reduce((acc, item) => {
    acc[item.day.toLowerCase()] = item;
    return acc;
  }, {});

  const currentDayShortName = moment().format("ddd").toLowerCase().slice(0, 3);
  return (
    <ul className="sunday">
      {weekDays.map((day) => {
        const dayData = workingDaysMap ? workingDaysMap[day.shortName] : null;
        const displayName =
          day.shortName === currentDayShortName ? lang("Today") : day.name;

        return (
          <li key={day.shortName} className="working-day-item">
            <p>{displayName}</p>
            {dayData ? (
              <p>
                {dayData?.open_time
                  ? moment(dayData?.open_time).format("hh:mm a")
                  : "00:00"}{" "}
                -{" "}
                {dayData?.open_time
                  ? moment(dayData?.close_time).format("hh:mm a")
                  : "00:00"}
              </p>
            ) : (
              <p>{lang("Closed")}</p>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default ServicedetailsIndex;
