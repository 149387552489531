import React, { useEffect, useState } from "react";
import {
  Tabs,
  Button,
  Select,
  Input,
  Checkbox,
  List,
  Typography,
  Row,
  Col,
  Space,
  Tooltip,
  Collapse,
  Image,
} from "antd";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  UserOutlined,
  ShareAltOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { Accordion } from "react-bootstrap";

import DeletebuttonImg from "../../assets/images/delete-button.png";
import VenueImg from "../../assets/images/venue-img.png";
import AdditemImg from "../../assets/images/add-item.png";
import apiPath from "../../constants/apiPath";
import useRequest from "../../hooks/useRequest";
import { useNavigate, useParams } from "react-router";
import { Severty, ShowToast } from "../../helper/toast";
import notfound from "../../assets/images/not_found.png";
import ConfirmModal from "../../components/ConfirmDeleteModal";
import Loader from "../../components/Loader";
import RightcheckImg from "../../assets/images/wirghtclick.png";
import Nodata from "../../assets/images/no-data.png";

import { AddGuest, ConfirmInvite, ShareEvent } from "./AddGuest";

import { DownloadExcel } from "../../components/ExcelFile";
import moment from "moment";
import ImportForm from "../../components/ImportForm";
import { useAuthContext } from "../../context/AuthContext";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";
import { BudgetListAttrShow } from "../../helper/functions";
const { Option } = Select;
const { Text } = Typography;

const { Panel } = Collapse;

function EventBudget() {
  const { TabPane } = Tabs;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState("");
  const [show, setShow] = useState(false);
  const [total, setTotal] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showPkgConfirm, setShowPkgConfirm] = useState(false);
  const [showCModalConfirm, setShowCModalConfirm] = useState(false);
  const [expanded, setExpanded] = useState(null);
  const [expenditure, setExpenditure] = useState(null);
  const { language } = useAppContext();

  const params = useParams();
  const { request } = useRequest();
  const navigate = useNavigate();

  const getEvents = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.userEventDetail}/${id}`,
      method: "GET",
      onSuccess: ({ data }) => {
        console.log(data, "data------------>");
        setLoading(false);
        console.log(data, "data...");
        setData(data);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  const getExpenditure = () => {
    console.log(data, "data--------->");

    if (
      data &&
      data?.categories &&
      Array.isArray(data?.categories) &&
      data?.categories?.length > 0
    ) {
      let totalOriginalPrice = data?.categories?.reduce((acc, category) => {
        let serviceTotal = category?.services?.reduce((serviceAcc, service) => {
          // Calculate total for services' original prices
          return serviceAcc + (service?.price || 0);
        }, 0);

        let packageTotal = category?.packages?.reduce((packageAcc, pkg) => {
          // Calculate total for packages' prices
          return packageAcc + (pkg?.price || 0);
        }, 0);

        return acc + serviceTotal + packageTotal;
      }, 0);

      setExpenditure(totalOriginalPrice);
    }
  };

  const deleteService = (id) => {
    const { event_id, service_id, provider_id, category_id } = selected;
    const payload = {
      service_id: service_id,
      provider_id: provider_id,
      category_id: category_id,
      event_id: event_id,
    };
    setLoading(true);
    request({
      url: `${apiPath.deleteService}`,
      method: "delete",
      data: payload,
      onSuccess: ({ data, status, message }) => {
        setLoading(false);
        if (status) {
          ShowToast(message, Severty.SUCCESS);
          setSelected("");

          setExpanded(null);

          setShowConfirm(false);
          setRefresh((prev) => !prev);
        } else {
          ShowToast(message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  const deletePackage = (id) => {
    const { event_id, package_id, provider_id, category_id } = selected;
    const payload = {
      package_id: package_id,
      provider_id: provider_id,
      category_id: category_id,
      event_id: event_id,
    };
    setLoading(true);
    request({
      url: `${apiPath.deletePackage}`,
      method: "delete",
      data: payload,
      onSuccess: ({ data, status, message }) => {
        setLoading(false);
        if (status) {
          ShowToast(message, Severty.SUCCESS);
          setSelected("");
          setExpanded(null);
          setShowPkgConfirm(false);
          setRefresh((prev) => !prev);
        } else {
          ShowToast(message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  const deleteCategory = () => {
    setLoading(true);
    const { event_id, event_category_id } = selected;
    const payload = {
      event_category_id: event_category_id,
      event_id: event_id,
    };
    request({
      url: `${apiPath.eventList}/budget-category/delete`,
      method: "delete",
      data: payload,
      onSuccess: ({ data, status, message }) => {
        setLoading(false);
        if (status) {
          ShowToast(message, Severty.SUCCESS);
          setSelected("");
          setShowCModalConfirm(false);
          setRefresh((prev) => !prev);
        } else {
          ShowToast(message, Severty.ERROR);
        }
      },
      onError: (err) => {
        setLoading(false);
        ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  const handleService = ({
    service_id,
    provider_id,
    category_id,
    event_id,
  }) => {
    setSelected({
      service_id: service_id,
      provider_id: provider_id,
      category_id: category_id,
      event_id: event_id,
    });
    setShowConfirm(true);
  };

  const handleDeletePackage = ({
    package_id,
    provider_id,
    category_id,
    event_id,
  }) => {
    setSelected({
      package_id: package_id,
      provider_id: provider_id,
      category_id: category_id,
      event_id: event_id,
    });
    setShowPkgConfirm(true);
  };

  const handleDeleteCategory = ({ event_category_id, event_id }) => {
    setSelected({ event_category_id: event_category_id, event_id: event_id });
    setShowCModalConfirm(true);
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    if (!data.length) getEvents(params.id);
  }, [params.id, refresh, total]);

  useEffect(() => {
    if (data) getExpenditure();
  }, [data]);

  const remainingBudget = data?.budget - expenditure;
  const isOverBudget = remainingBudget < 0;

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };
  const [activeKey, setActiveKey] = useState("0"); // set the initial active key to '0'

  const showName = (value) => {
    return language !== "en" && language !== null
      ? value?.[`${language}_name`] ?? value?.name
      : value?.name;
  };

  const handleNavigate = (providerId, serviceId) => {
    console.log(serviceId, "serviceId");
    navigate(
      `/provider/${providerId}`,
      serviceId ? { state: { serviceId } } : undefined
    );
  };

  return (
    <Main>
      <section>
        <div className="container for-all-short-w">
          <div class="event-list-hdr">
            <h3>{lang("My Event Budget")}</h3>
          </div>

          <Tabs defaultActiveKey="1" className="budget-main-hddr">
            <TabPane tab={lang("Budget")} key="1" className="budget-main">
              {loading ? (
                <Loader />
              ) : (
                <Collapse activeKey={activeKey} onChange={handleToggle}>
                  {data?.categories?.length && data?.categories?.length > 0 ? (
                    data?.categories?.map((item, index) => {
                      const totalsrPrice = item?.services?.reduce(
                        (acc, service) => acc + (service?.price || 0),
                        0
                      );

                      const totalPkgPrice = item?.packages?.reduce(
                        (acc, pkg) => acc + (pkg?.price || 0),
                        0
                      );

                      const totalPrice = totalsrPrice + totalPkgPrice;
                      const panelKey = index.toString();
                      return (
                        <Panel
                          header={
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <h2>
                                {language !== "en" && language !== null
                                  ? item?.category_id?.[`${language}_name`] ??
                                    item?.category_id?.name
                                  : item?.category_id?.name}
                              </h2>
                              <h1
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteCategory({
                                    event_category_id: item?._id,
                                    event_id: data?._id,
                                  });
                                }}
                              >
                                <img src={DeletebuttonImg} alt="" />
                              </h1>
                            </div>
                          }
                          key={panelKey}
                        >
                          {item?.services?.length && item?.services?.length > 0
                            ? item?.services?.map((service) => (
                                <div
                                  className="venue-lalsion"
                                  key={service?._id}
                                >
                                  <div
                                    className="venue-lalsion-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleNavigate(
                                        service?.provider_id?._id,
                                        service?.service_id?._id
                                      )
                                    }
                                  >
                                    <div className="venue-img">
                                      <div className="venue-main-immg">
                                        <Image
                                          preview={false}
                                          src={
                                            service?.service_id?.cover_image
                                              ? service?.service_id?.cover_image
                                              : service?.service_id?.service_id
                                                  ?.image || notfound
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="venie-m-txt">
                                        <h2>
                                          {service?.service_id?.name
                                            ? service?.service_id?.name
                                            : language !== "en" &&
                                              language !== null
                                            ? service?.service_id?.service_id?.[
                                                `${language}_name`
                                              ] ??
                                              service?.service_id?.service_id
                                                ?.name
                                            : service?.service_id?.service_id
                                                ?.name}
                                        </h2>
                                        <h3>
                                          <span>{lang("Vendor")}:</span>

                                          {language !== "en" &&
                                          language !== null
                                            ? service?.provider_id?.[
                                                `${language}_name`
                                              ] ?? service?.provider_id?.name
                                            : service?.provider_id?.name}
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="venue-cost">
                                      <h3>{lang("Actual cost")}:</h3>
                                      <p>JOD {service?.price ?? 0}</p>
                                    </div>
                                  </div>
                                  <div className="vensu-teext vensu-teext-667">
                                    <BudgetListAttrShow
                                      attributes={service?.options}
                                    />

                                    <div
                                      className="venue-beffo"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleService({
                                          service_id: service?.service_id?._id,
                                          provider_id:
                                            service?.provider_id?._id,
                                          category_id: item?.category_id?._id,
                                          event_id: data?._id,
                                        })
                                      }
                                    >
                                      <img src={DeletebuttonImg} alt="" />
                                    </div>
                                  </div>
                                </div>
                              ))
                            : ""}

                          {item?.packages?.length &&
                          item?.packages?.length > 0 ? (
                            item?.packages?.map((pkg) => (
                              <div
                                className="venue-lalsion"
                                key={pkg?._id}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/provider/${pkg?.provider_id?._id}`)
                                }
                              >
                                <div className="venue-lalsion-1">
                                  <div className="venue-img">
                                    <div className="venue-main-immg">
                                      <img
                                        src={
                                          pkg?.package_id?.cover_image
                                            ? pkg?.package_id?.cover_image
                                            : pkg?.provider_id?.logo
                                            ? pkg?.provider_id?.logo
                                            : notfound
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="venie-m-txt">
                                      <h2>
                                        {language !== "en" && language !== null
                                          ? pkg?.package_id?.[
                                              `${language}_name`
                                            ] ?? pkg?.package_id?.name
                                          : pkg?.package_id?.name}
                                      </h2>

                                      <h3>
                                        <span>{lang("Vendor")}:</span>
                                        {language !== "en" && language !== null
                                          ? pkg?.provider_id?.[
                                              `${language}_name`
                                            ] ?? pkg?.provider_id?.name
                                          : pkg?.provider_id?.name}
                                      </h3>
                                    </div>
                                  </div>
                                  <div className="venue-cost">
                                    <h3>{lang("Actual cost")}:</h3>
                                    <p>JOD {pkg?.price ?? 0}</p>
                                  </div>
                                </div>
                                <div className="vensu-teext vensu-teext-667">
                                  {pkg?.pkg_services?.length > 0 &&
                                    pkg.pkg_services.map((service) => (
                                      <div className="main-eve-listed">
                                        <h5>
                                          {service?.service_id?.name
                                            ? service?.service_id?.name
                                            : language !== "en" &&
                                              language !== null
                                            ? service?.service_id?.service_id?.[
                                                `${language}_name`
                                              ] ??
                                              service?.service_id?.service_id
                                                ?.name
                                            : service?.service_id?.service_id
                                                ?.name}
                                        </h5>
                                        <ul className="my-events-listed-44">
                                          <BudgetListAttrShow
                                            attributes={service.options}
                                          />
                                        </ul>
                                      </div>
                                    ))}

                                  <div
                                    className="venue-beffo"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeletePackage({
                                        package_id: pkg?.package_id?._id,
                                        provider_id: pkg?.provider_id?._id,
                                        category_id: item?.category_id?._id,
                                        event_id: data?._id,
                                      });
                                    }}
                                  >
                                    <img src={DeletebuttonImg} alt="" />
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="no service-page"></div>
                          )}
                          <div className="add-iemes">
                            <div className="add-items-1">
                              <button
                                onClick={() =>
                                  navigate(
                                    `/event-budget/addServices/${item?.category_id?._id}/${data?._id}`
                                  )
                                }
                              >
                                <h2>
                                  <img src={AdditemImg} alt="" />
                                </h2>
                                <h3>{lang("Add New Items")}</h3>
                              </button>
                            </div>
                            <div className="add-price">JOD {totalPrice}</div>
                          </div>
                        </Panel>
                      );
                    })
                  ) : (
                    <div className="Noquotation33Img">
                      <img src={Nodata} alt="No categories available" />
                    </div>
                  )}
                </Collapse>
              )}
              <div className="add-iemes main-add-for-scroll">
                <Row gutter={[24, 24]}>
                  <Col span={24} md={6}>
                    <div className="add-items-1">
                      <button
                        onClick={() =>
                          navigate(`/event-budget/addCategory/${data?._id}`)
                        }
                      >
                        <h2>
                          <img src={AdditemImg} alt="Add Item" />
                        </h2>
                        <h3>{lang("Add New Category")}</h3>
                      </button>
                    </div>
                  </Col>

                  <Col span={24} md={18}>
                    <div className="main-mmount-44">
                      <div className="add-price">
                        <h3>{lang("Total Budget")}:</h3>
                        <span className="pricing-main">JOD {data?.budget}</span>
                      </div>
                      <div className="add-price">
                        <h3> {lang("Total Expenditure")}: </h3>{" "}
                        <span className="pricing-main">JOD {expenditure}</span>
                      </div>
                      <div className="add-price color-danger">
                        <span
                          style={{
                            color: isOverBudget == true ? "red" : "green",
                          }}
                          className="pricing-main"
                        >
                          {!isOverBudget ? (
                            <PlusOutlined style={{ color: "green" }} />
                          ) : (
                            <MinusOutlined style={{ color: "red" }} />
                          )}
                          JOD {Math.abs(remainingBudget)}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </TabPane>

            <TabPane
              tab={`${lang("Guestlist")} ${total ? "(" + total + ")" : ""}`}
              key="2"
              className="budget-main"
            >
              <div className="main-all-div-guestlist">
                <GuestMultipleViews
                  shared={!!data?.isShared}
                  setTotal={setTotal}
                  event_id={params.id}
                  data={data}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </section>
      {showConfirm && (
        <ConfirmModal
          show={showConfirm}
          hide={() => {
            setShowConfirm(false);
            setSelected("");
          }}
          title={lang("Delete Service")}
          subtitle={lang(`Are you sure you want to delete this Service?`)}
          refresh={() => setRefresh((prev) => !prev)}
          onOk={() => deleteService()}
        />
      )}
      {showPkgConfirm && (
        <ConfirmModal
          show={showPkgConfirm}
          hide={() => {
            setShowPkgConfirm(false);
            setSelected("");
          }}
          title={lang("Delete Package")}
          subtitle={lang(`Are you sure you want to delete this Package?`)}
          refresh={() => setRefresh((prev) => !prev)}
          onOk={() => deletePackage()}
        />
      )}
      {showCModalConfirm && (
        <ConfirmModal
          show={showCModalConfirm}
          hide={() => {
            setShowCModalConfirm(false);
            setSelected("");
          }}
          title={lang("Delete Event Category")}
          subtitle={lang(
            `Are you sure you want to delete this Event Category?`
          )}
          refresh={() => setRefresh((prev) => !prev)}
          onOk={() => deleteCategory()}
        />
      )}
    </Main>
  );
}

const GuestMultipleViews = ({ data, shared, setTotal, event_id }) => {
  const [guestList, setGuestList] = useState([]);
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selected, setSelected] = useState("");
  const [members, setMembers] = useState(1);
  const [importVisible, setImportVisible] = useState(false);
  const [deleteGuest, setDeleteGuest] = useState([]);
  const [guestNumber, setGuestNumber] = useState(0);
  const [showConfirm, setShowConfirm] = useState(false);
  const [share, setShare] = useState(false);
  const [invitee, setInvitee] = useState();
  const [showDeleteGuest, setShowDeleteGuest] = useState(false);
  const { language } = useAppContext();

  const { userProfile } = useAuthContext();

  const { request } = useRequest();

  const handleMemberChange = (id, data) => {
    console.log(data, "handleMemberChange");
    request({
      url: apiPath.updateGuest + id,
      method: "PUT",
      data: { total_member: data },
      onSuccess: (data) => {
        if (data?.status) {
          setRefresh((x) => !x);
          console.log(data);
        } else {
          ShowToast(data.message, Severty.ERROR);
          setRefresh((x) => !x);
        }
        setMembers(0);
        setSelected("");
      },
      onError: (err) => {
        console.log(err);
        ShowToast(err?.message?.data?.response[0], Severty.ERROR);
      },
    });
  };

  const deleteAll = () => {
    if (deleteGuest <= 0) {
      return ShowToast("Please Select min. 1 Guest User", Severty.ERROR);
    }
    let ids = deleteGuest;
    request({
      url: apiPath.allDeleteGuest,
      method: "DELETE",
      data: { ids: ids },
      onSuccess: (data) => {
        console.log(data);
        setDeleteGuest([]);
        setShowDeleteGuest(false);
        ShowToast(data.message, Severty.SUCCESS);
        setRefresh((prev) => !prev);
      },
      onError: (err) => {
        console.log(err);
        ShowToast(err?.message?.data?.response[0], Severty.ERROR);
      },
    });
  };

  const handledeleteCheckbox = (guest) => {
    debugger;
    console.log(guest);
    setGuestNumber((prev)=>{
      if (deleteGuest.includes(guest?._id)) {
        return prev - guest?.total_member;
      } else {
        return prev + guest?.total_member; 
      }
    })
    setDeleteGuest((prev) => {
      if (prev.includes(guest?._id)) {
        return prev.filter((id) => id !== guest?._id);
      } else {
        return [...prev, guest?._id];
      }
    });
    console.log(guestNumber);
  };

  const getGuests = (id) => {
    setInvitee(id);
    request({
      url: apiPath.guest + `${id ? "/" + id : ""}?event_id=${event_id}`,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) return;
        setGuestList(data?.data || []);
        setTotal(data?.data?.length || 0);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleMemberCount = (change, action, prev) => {
    console.log(change, action, selected.total_member);
    const total =
      action === "increment" ? prev + Number(change) : prev - Number(change);

    setMembers(total);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!selected?._id) return;
      handleMemberChange(selected?._id, members);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [members]);

  useEffect(() => {
    getGuests();
    setDeleteGuest([]);
  }, [refresh]);

  const excelData = guestList?.map((row) => ({
    name: row?.name ? row?.name : "-",
    email: row?.email ? row?.email : "-",
    country_code: row?.country_code ? row?.country_code : "-",
    mobile_number: row?.mobile_number ? row?.mobile_number : "-",
    total_member: row?.total_member ? row?.total_member : "-",
    "Added On": moment(row?.created_at).format("DD-MM-YYYY"),
  }));

  const handleSelectAll = () => {
    if (deleteGuest.length === guestList.length) {
      setDeleteGuest([]);
    } else {
      setDeleteGuest(guestList.map((guest) => guest._id));
    }
  };

  return (
    <>
      <div className="main-share-bttnn">
        <Select
          defaultValue={lang("Please select guest")}
          className="main-bride-guest"
          onChange={(val) => getGuests(val)}
        >
          <Option key={1101} value={""}>
            <span style={{ textTransform: "capitalize" }}>All</span>
          </Option>
          {data?.guestData?.map((guest, index) => (
            <Option key={index} value={guest?._id}>
              <span style={{ textTransform: "capitalize" }}>{guest?.name}</span>
            </Option>
          ))}
        </Select>
        <Button
          onClick={() => {
            setShare(true);
          }}
          icon={<ShareAltOutlined />}
        />
      </div>
      <div className="guest-list-divvv">
        <Text>{lang("Guest Lists")}</Text>
        <Space size="middle" className="main-all-export button-h">
          <Button
            type="primary"
            className="invite-button-main for-blluee-colorr"
            onClick={() => DownloadExcel(excelData, "Guest")}
          >
            {lang("Export")}
          </Button>
          <Button
            type="primary"
            className="invite-button-main for-blluee-colorr"
            onClick={() => setImportVisible(true)}
          >
            {lang("Import")}
          </Button>
          <Button
            type="primary"
            className="invite-button-main for-blluee-colorr"
            onClick={() => {
              setShowConfirm(true);
            }}
            disabled={deleteGuest.length === 0}
          >
            {lang("Invite")}
          </Button>
          {!shared && (
            <Button
              type="danger"
              className="invite-button-main"
              onClick={() => setShowDeleteGuest(true)}
              disabled={deleteGuest?.length === 0}
            >
              {lang("Delete")}
            </Button>
          )}
          <Button className="invite-button-main" onClick={handleSelectAll}>
            {deleteGuest.length === guestList.length
              ? lang("Deselect All")
              : lang("Select All")}
          </Button>
        </Space>
      </div>
      <List
        dataSource={guestList}
        className="main-div-of-all-list"
        renderItem={(guest, index) => (
          <List.Item className="guest-list-name-all">
            <Row gutter={[24, 24]}>
              <Col span={24} md={8}>
                <div className="guestname-44">
                  <h4>
                    {" "}
                    <Checkbox
                      disabled={userProfile?._id !== guest.user_id}
                      onChange={() => handledeleteCheckbox(guest)}
                      checked={deleteGuest.includes(guest._id)}
                    />{" "}
                    {lang("Guest Full Name")}
                  </h4>
                  {/* <Select defaultValue={guest.name || "Guest name"}>
                    <Option value="Guest name">Guest name</Option>
                    <Option value="Other name">Other name</Option>
                  </Select> */}
                  <Input disabled value={guest?.name} />
                </div>
              </Col>

              <Col span={24} md={8}>
                <div className="guestname-44">
                  <h4>{lang("Mobile Number")}</h4>
                  <Input
                    disabled
                    value={
                      "+" + guest?.country_code + "-" + guest?.mobile_number
                    }
                  />
                </div>
              </Col>

              <Col span={24} md={8}>
                <div className="guestname-44 guestname-44-main-45">
                  <h4>{lang("Total Member")}</h4>
                  <Button
                    disabled={
                      userProfile?._id !== guest?.user_id ||
                      guest.total_member <= 1
                    }
                    shape="circle"
                    icon={<MinusCircleOutlined />}
                    size="small"
                    onClick={() => {
                      setSelected(guest);
                      handleMemberCount(1, "decrement", guest.total_member);
                    }}
                  />
                  <Text
                    style={{ margin: "0 8px", width: 32, textAlign: "center" }}
                  >
                    {guest.total_member?.toString()?.padStart(2, "0")}
                  </Text>
                  <Button
                    disabled={userProfile?._id !== guest?.user_id}
                    shape="circle"
                    icon={<PlusCircleOutlined />}
                    size="small"
                    onClick={() => {
                      setSelected(guest);
                      handleMemberCount(1, "increment", guest.total_member);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </List.Item>
        )}
        bordered
      />

      <Tooltip title={lang("Add New Guest")}>
        <Button
          type="primary"
          shape="circle"
          size="large"
          className="add-new-guest-aag33"
          onClick={() => setShow(true)}
        >
          <PlusCircleOutlined /> {lang("Add New Guest")}
        </Button>
      </Tooltip>

      {share && (
        <ShareEvent
          show={share}
          hide={() => {
            setShare(false);
            setSelected("");
          }}
          selected={selected}
          event_id={event_id}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}
      {show && (
        <AddGuest
          show={show}
          hide={() => {
            setShow(false);
            setSelected("");
          }}
          event_id={event_id}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {showConfirm && (
        <ConfirmInvite
          show={showConfirm}
          hide={() => {
            setShowConfirm((prev) => !prev);
            setSelected("");
          }}
          event_id={event_id}
          selected={deleteGuest}
          refresh={() => setRefresh((prev) => !prev)}
          totalGuest={guestNumber}
        />
      )}

      {importVisible && (
        <ImportForm
          path={apiPath.importGuest}
          sectionName={"Guest"}
          show={importVisible}
          hide={() => setImportVisible(false)}
          existingData={guestList}
          event_id={event_id}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {showDeleteGuest && (
        <ConfirmModal
          show={showDeleteGuest}
          hide={() => {
            setShowDeleteGuest(false);
            setDeleteGuest([]);
          }}
          title={lang("Delete Guest")}
          subtitle={lang(`Are you sure you want to delete selected Guest?`)}
          refresh={() => setRefresh((prev) => !prev)}
          onOk={() => deleteAll()}
        />
      )}
    </>
  );
};

export default EventBudget;
