import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Col,
  Row,
  InputNumber,
  Checkbox,
  List,
  Radio,
} from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "bootstrap/dist/css/bootstrap.min.css";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import { PhoneNumberField } from "../../components/InputField";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import PhoneInput from "react-phone-input-2";

function AddGuest({ event_id, hide, show, loading, refresh }) {
  const [mobileNumber, setMobileNumber] = useState(null);
  const [form] = Form.useForm();
  const { request } = useRequest();

  const onSubmit = (values) => {
    const payload = { ...values };
    payload.country_code = mobileNumber?.country_code;
    payload.mobile_number = mobileNumber?.mobile_number;
    payload.event_id = event_id;

    request({
      url: apiPath.guest,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          form.resetFields();
          hide();
          refresh();
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };

  return (
    <>
      <Modal
        open={show}
        onCancel={hide}
        title="Basic Modal"
        width={749}
        className="noevent-model"
        okText={lang("Submit")}
        cancelText={lang("Back")}
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
          className: "submit-button", // Custom class for Submit button
        }}
        cancelButtonProps={{
          className: "back-button", // Custom class for Back button
        }}
      >
        <div className="no-active_event">
          <div className="event-backgg-header">
            <h2>{lang("Add Guest")}</h2>
          </div>
          <div className="noevent-text">
            <Form
              className="noevent-maain-model"
              id="create"
              form={form}
              onFinish={onSubmit}
              layout="vertical"
            >
              <Row gutter={[24, 0]}>
                <Col span={24} xl={24} md={24}>
                  <Form.Item
                    className=""
                    name="name"
                    rules={[
                      {
                        max: 250,
                        message: lang(
                          "Name should contain more then 250 characters!"
                        ),
                      },
                      {
                        required: true,
                        message: lang("Please Enter  Name"),
                      },
                    ]}
                  >
                    <Input placeholder={lang("Enter Full Name")} />
                  </Form.Item>
                </Col>
                <Col span={24} xl={12} md={12}>
                  <Form.Item
                    className="username"
                    // label={lang("Email Address")}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: lang("Please enter a valid email address!"),
                      },
                      // {
                      //   max: 255,
                      //   message: lang(
                      //     "Email address not more then 255 characters!"
                      //   ),
                      // },
                      // {
                      //   required: true,
                      //   message: lang("Please enter email!"),
                      // },
                    ]}
                  >
                    <Input placeholder={lang("Enter Email to send invite")} />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item className="username">
                    <PhoneInput
                      inputProps={{
                        name: "mobile",
                        autoFocus: false,
                        placeholder: lang("Enter Phone Number"),
                      }}
                      isValid={(value, country) => {}}
                      //value={}
                      country={"jo"}
                      preferredCountries={["jo"]}
                      onChange={handleMobileNumberChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

function ConfirmInvite({
  event_id,
  hide,
  show,
  loading,
  refresh,
  selected,
  totalGuest,
}) {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [cards, setCards] = useState([]);
  const { userProfile } = useAuthContext();
  const params = useParams();

  const getInivitationCard = (values) => {
    console.log("Total Guests : ", selected);
    request({
      url: `${apiPath.inviteTemplate}${
        params.id ? `/${params.id}` : ""
      }?totalGuest=${totalGuest}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        setCards(data ?? []);
        console.log(data, "setCards");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const onSubmit = (values) => {
    const payload = { ...values };
    payload.event_id = event_id;
    payload.ids = selected;
    payload.template_id = values?.template_id;
    payload.guest_number = values?.template_id;
    //  return console.log(values.template_id,"template_id")

    request({
      url: apiPath.inviteUserGuest,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          hide();
          refresh();
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    getInivitationCard();
    console.log(selected, "selected....");
  }, []);

  return (
    <>
      <Modal
        open={show}
        onCancel={hide}
        title="Basic Modal"
        width={1000}
        className="noevent-model noevent-modelmain-uuui-hhh-kkll"
        okText={lang("Submit")}
        cancelText={lang("Back")}
        okButtonProps={{
          form: "create",
          htmlType: "submit",
          loading: loading,
          className: "submit-button", // Custom class for Submit button
        }}
        cancelButtonProps={{
          className: "back-button", // Custom class for Back button
        }}
      >
        <div className="no-active_event">
          <div className="event-backgg-header">
            <h2>{lang("Invite Guest")}</h2>
          </div>
          <div className="">
            <Form
              className="noevent-maain-model"
              id="create"
              form={form}
              onFinish={onSubmit}
              layout="vertical"
            >
              <Row gutter={[24, 0]}>
                <Col span={24} xl={24} md={24}>
                  <div className="main-invite-all-text-jjh">
                    {lang("Please Select A Invitation Card ?")}
                  </div>
                </Col>
                <Col>
                  <div className="emailTempOuter" style={{ cursor: "pointer" }}>
                    <Row gutter={[24, 24]}>
                      <Col xl={24} md={24} span={24}>
                        <Form.Item
                          className="username"
                          name="template_id"
                          rules={[
                            {
                              required: true,
                              message: lang("Please select a Template!"),
                            },
                          ]}
                        >
                          <Radio.Group>
                            {cards?.length &&
                              cards?.map((item, index) => (
                                <Radio value={item?._id} className="emailBody">
                                  {
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    />
                                  }
                                </Radio>
                              ))}
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
}

function ShareEvent({ show, hide, loading, refresh, event_id }) {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSend = () => {
    let payload = { selectedUsers };
    payload.event_id = event_id;
    request({
      url: apiPath.eventCollab,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const handleBack = () => {
    hide();
  };

  const handleFormSubmit = (values) => {
    setSelectedUsers([...selectedUsers, values]);
    form.resetFields();
  };

  return (
    <Modal
      open={show}
      onCancel={hide}
      title={lang("Invite Guests")}
      width={749}
      className="noevent-model"
      footer={[
        <Button key="back" onClick={handleBack} className="back-button">
          {lang("Back")}
        </Button>,
        <Button
          disabled={!selectedUsers?.length}
          key="send"
          type="primary"
          onClick={handleSend}
          loading={loading}
          className="submit-button"
        >
          {lang("Send")}
        </Button>,
      ]}
    >
      <div className="no-active_event">
        <div className="event-backgg-header">
          <h2>{lang("Share Event")}</h2>
        </div>
        <div className="noevent-text noevent-textmain-987gdgd">
          <Form form={form} onFinish={handleFormSubmit} layout="inline">
            <Form.Item
              name="name"
              rules={[{ required: true, message: lang("Please enter a name") }]}
            >
              <Input
                placeholder={lang("Enter Name")}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: lang("Please enter an email") },
              ]}
            >
              <Input
                placeholder={lang("Enter Email")}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="invite-button-main for-blluee-colorr"
              >
                {lang("Add Guest")}
              </Button>
            </Form.Item>
          </Form>
          <List
            dataSource={selectedUsers}
            renderItem={(user) => (
              <List.Item>
                {user.name} ({user.email})
              </List.Item>
            )}
          />
        </div>
      </div>
    </Modal>
  );
}

export { AddGuest, ConfirmInvite, ShareEvent };
