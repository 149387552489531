import React, { useEffect, useRef, useState } from "react";
import { Image, Tabs } from "antd";
import OwlCarousel from "react-owl-carousel3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Carousel from "react-bootstrap/Carousel";
import Main from "../../components/layout/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import { renderToStaticMarkup } from "react-dom/server";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Musicevent1Img from "../../assets/images/slider-shape-white.svg";
import Musicevent2Img from "../../assets/images/music event img-3.png";
import Musicevent3Img from "../../assets/images/music event img-4.png";
import Gallery1Img from "../../assets/images/gallery-1.png";
import Gallery2Img from "../../assets/images/gallery-2.png";
import bannerImage from "../../assets/images/banner-all.png";
import notfound from "../../assets/images/not-found-img.png";

import AboutImg from "../../assets/images/about-img.png";
import BannerImg from "../../assets/images/banner-all.png";
import { useNavigate, useParams } from "react-router";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/apiPath";
import InviteFriends from "../../components/InviteFriends";
import NewnoactiveImg from "../../assets/images/newimage-for-noactive.png";

import moment from "moment";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";
import CommonFunction from "../../helper/CommonFunction";
import Loader from "../../components/Loader";

const { TabPane } = Tabs;

const responsiveSettings = {
  0: {
    items: 1,
  },
  400: {
    items: 2,
  },
  600: {
    items: 3,
  },
  800: {
    items: 4,
  },
  1000: {
    items: 4,
  },
  1200: {
    items: 5,
  },
  1400: {
    items: 6,
  },
  1600: {
    items: 7,
  },
  1800: {
    items: 8,
  },
};

const responsiveSettings1 = {
  0: {
    items: 1,
  },
  400: {
    items: 2,
  },
  600: {
    items: 3,
  },
  1000: {
    items: 4,
  },
  1200: {
    items: 5,
  },
  1400: {
    items: 6,
  },
  1600: {
    items: 7,
  },
  1800: {
    items: 8,
  },
};

const onChange = (key) => {
  console.log(`Active tab key: ${key}`);
};

function Home() {
  const [categories, setCategories] = useState([]);
  const [events, setHomeEvents] = useState([]);
  const [providers, setProviders] = useState([]);
  const [about, setAbout] = useState("");
  const [banner, setBanner] = useState([]);
  const [topPackages, setTopPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  // const [time, setTime] = useState([]);
  const navigate = useNavigate();
  const { request } = useRequest();
  const { language, setQuotationCount } = useAppContext();
  const commonFunction = CommonFunction();

  const fetchData = () => {
    setLoading(true);
    request({
      url: apiPath.baseURL + "app/home",
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (!data.status) return;
        setHomeEvents(data.event);
        setCategories(data.category);
        setProviders(data?.featured_provider);
        setTopPackages(data?.top_packages);
        setBanner(data.banner);
        setQuotationCount(data.quoteReplyCount);
      },
      onError: (err) => {
        setLoading(false);
        console.log(err);
      },
    });
  };

  const fetchCount = () => {
    request({
      url: apiPath.baseURL + "/app/get-count",
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) return;
        setCount(data.event);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const getAbout = () => {
    request({
      url: `${apiPath.about}/about-us`,
      method: "GET",
      onSuccess: (data) => {
        if (!data.status) return;
        setAbout(data.docs[0]);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    getAbout();
  }, []);

  const handleNavProvider = (id) => {
    alert("Commonfunction");
    let vendor_id = id ? id : "";
    commonFunction.updateProviderViewCount(vendor_id);
  };
  return (
    <Main>
      <>
        <section className="owl-cl">
          {loading ? (
            <Loader />
          ) : (
            <BannerSection banner={banner} language={language} />
          )}
        </section>
        <section className="tabbs-section">
          <div className="container-fluid">
            <Tabs
              defaultActiveKey="1"
              onChange={onChange}
              className="category-tab"
            >
              <TabPane
                tab={lang("Categories")}
                className="category-button"
                key="1"
              >
                {loading ? (
                  <Loader />
                ) : (
                  <OwlCarousel
                    items="8"
                    className="owl-theme container-fluid"
                    loop={false}
                    autoplay={false}
                    autoplayHoverPause={true}
                    rtl={true}
                    margin={40}
                    nav={true}
                    dots={false}
                    responsive={responsiveSettings}
                    navText={[
                      renderToStaticMarkup(
                        <LeftOutlined className="custom-nav-prev" />
                      ),
                      renderToStaticMarkup(
                        <RightOutlined className="custom-nav-next" />
                      ),
                    ]}
                  >
                    {categories?.length && categories.length > 0 ? (
                      categories?.map((item, idx) => {
                        return (
                          <div className="item">
                            <div className="wedding-main">
                              <div
                                className="birthday-new"
                                onClick={() => {
                                  navigate(`/service/${item?._id}`);
                                }}
                              >
                                <img
                                  src={item?.image ? item?.image : notfound}
                                  alt="CategoryImage"
                                />
                                <h4 title={item?.name ?? ""}>
                                  {language !== "en" && language !== null
                                    ? item?.[`${language}_name`] ?? item?.name
                                    : item?.name}
                                </h4>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="item">
                        <div className="wedding-main ">
                          <div className="view-all-items">
                            <div className="new-active-img">
                              <img src={NewnoactiveImg} />
                            </div>
                            <h4>{lang("No Active Categories")}</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </OwlCarousel>
                )}
              </TabPane>
              <TabPane tab={lang("Occasions")} key="2">
                {loading ? (
                  <Loader />
                ) : (
                  <OwlCarousel
                    items="5"
                    className="owl-theme"
                    loop={false}
                    autoplay={false}
                    margin={40}
                    nav={true}
                    dots={false}
                    responsive={responsiveSettings}
                    navText={[
                      renderToStaticMarkup(
                        <LeftOutlined className="custom-nav-prev" />
                      ),
                      renderToStaticMarkup(
                        <RightOutlined className="custom-nav-next" />
                      ),
                    ]}
                  >
                    {events?.length && events.length > 0 ? (
                      events?.map((item, idx) => {
                        return (
                          <div className="item">
                            <div className="wedding-main">
                              <div
                                className="birthday-new"
                                onClick={() =>
                                  navigate(`/service-by-event/${item?._id}`)
                                }
                              >
                                <img
                                  src={item?.image ? item?.image : notfound}
                                  alt="CategoryImage"
                                />
                                <h4 title={item?.name ?? ""}>
                                  {language !== "en" && language !== null
                                    ? item?.[`${language}_name`] ?? item?.name
                                    : item?.name}
                                </h4>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="item">
                        <div className="wedding-main ">
                          <div className="view-all-items">
                            <div className="new-active-img">
                              <img src={NewnoactiveImg} />
                            </div>
                            <h4>{lang("No Active Events")}</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </OwlCarousel>
                )}
              </TabPane>
              <TabPane tab={lang("Suggestions")} key="3">
                {loading ? (
                  <Loader />
                ) : (
                  <OwlCarousel
                    items="5"
                    className="owl-theme"
                    loop={false}
                    autoplay={false}
                    margin={40}
                    nav={true}
                    dots={false}
                    responsive={responsiveSettings}
                    navText={[
                      renderToStaticMarkup(
                        <LeftOutlined className="custom-nav-prev" />
                      ),
                      renderToStaticMarkup(
                        <RightOutlined className="custom-nav-next" />
                      ),
                    ]}
                  >
                    {providers?.length && providers?.length > 0 ? (
                      providers?.map((item) => (
                        <div className="item">
                          {item.is_featured && (
                            <div className="featured-type">
                              {" "}
                              <h3>{lang("Featured")}</h3>
                            </div>
                          )}
                          <div className="wedding-main">
                            <div
                              className="birthday-new"
                              onClick={() => handleNavProvider(item?._id)}
                            >
                              <img
                                src={
                                  item?.logo
                                    ? item.logo
                                    : item?.selected_category_id?.image
                                    ? item?.selected_category_id?.image
                                    : notfound
                                }
                                alt="Birthday"
                              />
                              <h4 title={item?.name ?? ""}>
                                {language !== "en" && language !== null
                                  ? item?.[`${language}_name`] ?? item?.name
                                  : item?.name}
                              </h4>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="item">
                        <div className="wedding-main ">
                          <div className="view-all-items">
                            <div className="new-active-img">
                              <img src={NewnoactiveImg} />
                            </div>
                            <h4>{lang("No Active Providers")}</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </OwlCarousel>
                )}
              </TabPane>
              <TabPane tab={lang("Providers")} key="4">
                {loading ? (
                  <Loader />
                ) : (
                  <OwlCarousel
                    items="7"
                    className="owl-theme"
                    loop={false}
                    autoplay={false}
                    margin={40}
                    nav={true}
                    dots={false}
                    responsive={responsiveSettings}
                    navText={[
                      renderToStaticMarkup(
                        <LeftOutlined className="custom-nav-prev" />
                      ),
                      renderToStaticMarkup(
                        <RightOutlined className="custom-nav-next" />
                      ),
                    ]}
                  >
                    {providers?.length && providers?.length > 0 ? (
                      providers?.map((item) => (
                        <div className="item">
                          {item.is_featured && (
                            <div className="featured-type">
                              {" "}
                              <h3>{lang("Featured")}</h3>
                            </div>
                          )}
                          <div className="wedding-main">
                            <div
                              className="birthday-new"
                              onClick={() => handleNavProvider(item?._id)}
                            >
                              <img
                                src={
                                  item?.logo
                                    ? item.logo
                                    : item?.selected_category_id?.image
                                    ? item?.selected_category_id?.image
                                    : notfound
                                }
                                alt="Birthday"
                              />
                              <h4 title={item?.name ?? ""}>
                                {language !== "en" && language !== null
                                  ? item?.[`${language}_name`] ?? item?.name
                                  : item?.name}
                              </h4>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="item">
                        <div className="wedding-main ">
                          <div className="view-all-items">
                            <div className="new-active-img">
                              <img src={NewnoactiveImg} />
                            </div>
                            <h4>{lang("No Active Providers")}</h4>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="item">
                      <div className="wedding-main ">
                        <div
                          className="view-all-items"
                          onClick={() => navigate(`/all-providers`)}
                        >
                          <h4 className="for-view-all">{lang("View All")}</h4>
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                )}
              </TabPane>
            </Tabs>
          </div>
        </section>
        <section className="abt-us" id="about-us">
          <div className="container">
            <div className="abouut-uss-main">
              {loading ? (
                <Loader />
              ) : (
                <div className="abouut-us-txxt ">
                  <h3>{lang("About Us")}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        language !== "en" && language !== null
                          ? about?.[`${language}_description`] ??
                            about?.description
                          : about?.description,
                    }}
                  />
                </div>
              )}
              <div className="abouut-us-img ">
                <img src={AboutImg} alt="#" />
              </div>
            </div>
          </div>
        </section>
        <section className="galllery-sec" id="gallary">
          {topPackages?.length ? (
            <div className="container-fluid">
              <div className="occasions-main_2 Wedding ">
                <h2>{lang("Packages")}</h2>
                {loading ? (
                  <Loader />
                ) : (
                  <OwlCarousel
                    items="6"
                    className="owl-theme"
                    loop={false}
                    margin={41}
                    nav={true}
                    dots={false}
                    responsive={responsiveSettings1}
                    navText={[
                      renderToStaticMarkup(
                        <LeftOutlined className="custom-nav-prev" />
                      ),
                      renderToStaticMarkup(
                        <RightOutlined className="custom-nav-next" />
                      ),
                    ]}
                  >
                    <GallerySection
                      packages={topPackages}
                      language={language}
                    />
                  </OwlCarousel>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </section>
        {/* <InviteFriends /> */}
      </>
    </Main>
  );
}

const GallerySection = ({ packages, language }) => {
  const navigate = useNavigate();

  return (
    <>
      {packages?.length && packages.length > 0 ? (
        packages?.map((item) => (
          <div className="item">
            <div className="wedding-main">
              <div
                className="birthday-new"
                onClick={() =>
                  navigate(`/provider/${item?.vendor_id?._id}`, {
                    state: { tab: "2" },
                  })
                }
              >
                <img
                  src={
                    item?.cover_image
                      ? item?.cover_image
                      : item?.selected_category_id?.image
                      ? item?.selected_category_id?.image
                      : item?.vendor_id?.logo || notfound
                  }
                />
                <div className="galleryhome-uinn">
                  <h3 title={item?.name ?? ""}>
                    {language !== "en" && language !== null
                      ? item?.[`${language}_name`] ?? item?.name
                      : item?.name}
                  </h3>
                  <span title={item?.vendor_id?.name ?? ""}>
                    {language !== "en" && language !== null
                      ? item?.vendor_id?.[`${language}_name`] ??
                        item?.vendor_id?.name
                      : item?.vendor_id?.name}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="item">
          <div className="wedding-main ">
            <div className="view-all-items">
              <div className="new-active-img">
                <img src={NewnoactiveImg} />
              </div>
              <h4>{lang("No Active packages")}</h4>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const BannerSection = ({ banner, language }) => {
  const [index, setIndex] = useState(0);
  const [intervalTime, setIntervalTime] = useState(null);
  const [time, setTime] = useState([]);
  const navigate = useNavigate();
  const { request } = useRequest();

  const navigateView = ({
    banner_id,
    type,
    normal_link,
    category_id,
    vendor_id,
  }) => {
    updateBannerClick(banner_id);
    if (type === "Category") {
      navigate(`/service/${category_id}`);
    } else if (type === "Provider") {
      navigate(`/provider/${vendor_id}`);
    } else if (type === "Normal") {
      if (normal_link) window.open(normal_link);
    }
  };

  const updateBannerClick = (id) => {
    request({
      url: `${apiPath.bannerClicks}/${id}`,
      method: "PUT",
      onSuccess: (data) => {
        console.log(data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  useEffect(() => {
    if (banner && banner.length > 0) {
      setIntervalTime(parseInt(banner?.[0]?.rotation_time) * 1000);
    }
  }, [banner]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
    if (banner && banner[selectedIndex]) {
      setIntervalTime(parseInt(banner[selectedIndex]?.rotation_time) * 1000);
    }
  };

  return (
    <>
      <Carousel
        nav={false}
        activeIndex={index}
        onSelect={handleSelect}
        interval={intervalTime}
      >
        {banner.length && banner.length > 0 ? (
          banner?.map((item, idx) => (
            <Carousel.Item key={idx}>
              <div className="main-banner-img22">
                <img src={item?.image ? item.image : BannerImg} alt="" />
              </div>

              <div
                className="hdr-img-inner hdr-img-inner-23"
                onClick={() =>
                  item?.banner_for &&
                  (item?.banner_link ||
                    item?.category_id?._id ||
                    item?.vendor_id?._id)
                    ? navigateView({
                        banner_id: item?._id,
                        type: item?.banner_for,
                        normal_link: item?.banner_link,
                        category_id: item?.category_id?._id,
                        vendor_id: item?.vendor_id?._id,
                      })
                    : ""
                }
              >
                <img src={Musicevent3Img} alt="" />
              </div>
            </Carousel.Item>
          ))
        ) : (
          <Carousel.Item>
            <img src={BannerImg} alt="" />
            <Carousel.Caption>
              <div className="livemain">
                <div className="live-txt">
                  {/* <p>{lang("Welcome To Planit")}</p> */}
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        )}
      </Carousel>
    </>
  );
};

export default Home;
