import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Rate,
  Row,
  Avatar,
  Tabs,
  List,
} from "antd";
import axios from "axios";
import moment from "moment";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useContext, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../components/layout/Footer";

import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import StarlayerImg from "../../assets/images/star-layers.png";
import HappyfaceImg from "../../assets/images/jellow.png";
import starpImg from "../../assets/images/stars_1.png";
import starp1Img from "../../assets/images/stars_2.png";
import lang from "../../helper/langHelper";
import Loader from "../../components/Loader";
import Prouser from "../../assets/images/user.png";
import notfound from "../../assets/images/not_found.png";
import { useAppContext } from "../../context/AppContext";

window.Buffer = window.Buffer || require("buffer").Buffer;

const { TabPane } = Tabs;

function ProviderRating() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("all");
  const [reviews, setReviews] = useState([]);
  const [selected, setSelected] = useState([]);
  const [ratingAvg, setRatingAvg] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [myRating, setMyRating] = useState(null);
  const [modalRating, setModalRating] = useState(null);
  const {language} = useAppContext()
  const [form] = Form.useForm();
  const { request } = useRequest();
  const navigate = useNavigate();
  const params = useParams();
  const filteredReviews =
    selectedTab === "all"
      ? reviews
      : reviews.filter((review) => review.rating === parseInt(selectedTab));

  const onSubmit = (values) => {
    const payload = { ...values };
    payload.reviewee_id = selected._id ? selected._id : "";
    
    request({
      url: myRating ? `${apiPath.providerRating}/${myRating?._id}` : `${apiPath.providerRating}`,
      method: myRating ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        if (data.status) {
          setShow(false);
          ShowToast(data.message, Severty.SUCCESS);
          setMyRating(null)
          form.resetFields();
          setRefresh(() => (prev) => !prev);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (err) => {
        ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  const fetchData = (id) => {
    setLoading(true);
    request({
      url: `${apiPath.providerRatingList}/${id}`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          setReviews(data?.data);
          setSelected(data.provider);
          setRatingAvg(data?.rating);
          setMyRating(data?.my_rating);
          console.log(data?.my_rating, "------------");
        }
      },
      onError: (err) => {
        setLoading(false);

        ShowToast(err, Severty.ERROR);
        console.log(err);
      },
    });
  };

  useEffect(() => {
    if (!params.id) return;
    setLoading(true);
    fetchData(params.id);
  }, [params.id, refresh]);

  const totalRatings =
    ratingAvg?.one +
    ratingAvg?.two +
    ratingAvg?.three +
    ratingAvg?.four +
    ratingAvg?.five;

  const getPercentage = (count) =>
    totalRatings > 0 ? (count / totalRatings) * 100 : 0;

  const ratingsData = [
    { star: 5, count: ratingAvg.five },
    { star: 4, count: ratingAvg.four },
    { star: 3, count: ratingAvg.three },
    { star: 2, count: ratingAvg.two },
    { star: 1, count: ratingAvg.one },
  ];
const handleFormData = () =>{
  form.setFieldsValue({...myRating})
}
  return (
    <>
      <div className="account-outers">
        <Row>
          {loading ? (
            <Loader />
          ) : (
            <Col span={24} lg={18} xl={15} className="mx-auto  amt-auto">
              <div className="headding-reting">
                <h6>{lang("Review & Rating")}</h6>
                <Button
                  type="primary"
                  className="leave-feedback "
                  onClick={() => {
                    setShow(true);
                    handleFormData()
                  }}
                >
                  {myRating ? lang("Edit Feedback") :  lang("Leave Us Feedback")}
                </Button>
              </div>
              <div className="review-rating">
                <div className="header-rettings">
                  <Row>
                    <Col span={24} sm={12} md={4}>
                      <div className="rating-value">
                        <h1>{ratingAvg?.avgRating ?? 0}</h1>
                        <Rate
                          disabled
                          defaultValue={ratingAvg?.avgRating ?? 0}
                          style={{ fontSize: 24 }}
                        />
                        <p>{reviews?.length ? reviews.length : 0} {lang("reviews")}</p>
                      </div>
                    </Col>
                    <Col span={24} sm={12} md={20}>
                      <div className="rating-breakdown">
                        {ratingsData?.map((item) => (
                          <div className="rating-item" key={item.star}>
                            <span className="rate-count">
                              <Rate
                                disabled
                                defaultValue={item?.star}
                                count={1}
                              />
                              {item?.star}
                            </span>
                            <div className="bar">
                              <div
                                className="filled"
                                style={{
                                  width: `${getPercentage(item.count)}%`,
                                }}
                              ></div>
                            </div>
                            <span className="rate-last-count">
                              {getPercentage(item?.count).toFixed(2)}%
                            </span>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>
                <Tabs
                  className="rate-tab-main"
                  defaultActiveKey="all"
                  onChange={setSelectedTab}
                >
                  <TabPane tab={lang("All")} key="all" className="rate-tab">
                    <div className="headding-reting">
                      <h6>{lang("Comments")}</h6>
                      <span className="rate-count">
                        {" "}
                        <Rate
                          disabled
                          defaultValue={ratingAvg?.avgRating ?? 0}
                        />
                        {ratingAvg?.avgRating ?? 0}
                      </span>
                    </div>
                    {renderReviews(filteredReviews)}
                  </TabPane>
                  {[5, 4, 3, 2, 1].map((star) => (
                    <TabPane tab={`${star}`} key={star}>
                      {renderReviews(
                        filteredReviews.filter(
                          (review) => review.rating === star
                        )
                      )}
                    </TabPane>
                  ))}
                </Tabs>
              </div>
              <Button
                  type="primary"
                  className="m-2 leave-feedback "
                  style={{width: 100}}
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  {lang("Back")}
                </Button>
            </Col>
          )}
        </Row>
        {show && (
          <Modal
            open={show}
            width={700}
            okText={lang("Submit")}
            onCancel={() => {
              setShow(false);
              form.resetFields();
            }}
            okButtonProps={{
              form: "create",
              htmlType: "submit",
              loading: loading,
              className: "submit-button", // Add your custom class here
            }}
            cancelButtonProps={{ style: { display: "none" } }} // Hide the cancel button
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="tab_modal"
          
          >
            <div className="review-form">
              <div className="reviewheader">
                <img
                  src={selected?.logo ? selected?.logo : selected?.selected_category_id?.image ? selected?.selected_category_id?.image : notfound}
                  alt="Logo"
                  className="logo"
                />
                <h2>{selected?.name ?? ""}</h2>
                <p>
                  {selected?.category_id &&
                    selected?.category_id?.length > 0 &&
                    selected?.category_id?.map((item, index) => (
                      <span key={item._id}>
                         {language !== "en" && language !== null
                                        ? item?.[`${language}_name`] ??
                                          item?.name
                                        : item?.name}
                        {index < selected?.category_id?.length - 1 && " |"}
                      </span>
                    ))}
                </p>
              </div>
              <div className="events-review-tital">
                <h3>{lang("How was your Event with")} {selected?.name ?? lang("Provider")}</h3>
              </div>

              <Form
                id="create"
                form={form}
                onFinish={onSubmit}
                layout="vertical"
                className="row-col"
                initialValues={{
                  rating: 0,
                 
                }}
              >
                <Row gutter={[24, 0]}>
                  <Col xl={24} md={24} span={24}>
                    <div className="review-stars">
                      <p>{lang("Your overall rating")}</p>
                      <Form.Item
                        className="username"
                        name="rating"
                        rules={[
                          {
                            required: false,
                            message: lang("Please select a Rate!"),
                          },
                        ]}
                      >
                        <Rate defaultValue={0} />
                      </Form.Item>
                    </div>

                  </Col>
                  <Col xl={24} md={24} span={24}>
                    <Form.Item
                      className="username review-rename mt-3"
                      name="feedback"
                      label={lang("Add detailed review")}
                      rules={[
                        {
                          required: true,
                          message: lang("Please enter your feedback!"),
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{ color: "black", fontSize: "13px" }}
                        placeholder={lang("Enter your comments")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal>
        )}
      </div>

      <Footer />
    </>
  );
}

const renderReviews = (reviews) => (
  <List
    className="list-rettings"
    itemLayout="vertical"
    dataSource={reviews}
    renderItem={(item, index) => (
      <List.Item className="list-items-reteing" key={index}>
        <List.Item.Meta
          avatar={<Avatar src={item?.reviewer_id?.image ?? Prouser} />}
          title={
            <span>
              <div className="name-textname">
                {item?.reviewer_id?.name ?? ""}
              </div>{" "}
              <div className="ratting-points">
                {" "}
                <Rate
                  className="ratting-icons"
                  disabled
                  defaultValue={item?.rating ?? 0}
                />
                <span>{item?.rating ?? ""}</span>
              </div>
            </span>
          }
          description={
            item?.created_at ? moment(item?.created_at).format("DD-MM-yy") : ""
          }
        />
        <div className="decs-reted">{item?.feedback ?? ""}</div>
      </List.Item>
    )}
  />
);

export default ProviderRating;
